import React, { useContext } from 'react';
import './Header.css';
import Logo from '../assets/images/icons/probe-digital-logo.svg';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/Context';
import { scrollToElement } from '../utils/helper';
import { useAuth } from '../context/AuthProvider';

function Header() {
  const { openLogin, openSignup } = useAuth();
  const { user } = useContext(AppContext);
  const navigate = useNavigate();

  const checkUserLogin = () => {
    if (user) {
      navigate('/ccj-dashboard/judgement-checker');
    } else {
      navigate('/ccj-dashboard/judgement-checker', { replace: true });
      window.location.reload();
    }
  };

  const navigateToPricingPage = () => {
    navigate('/pricing-page');
    window.location.reload();
  };

  const navigateToHome = () => {
    navigate('/');
  };

  const moveToServices = () => {
    const currentPath = window.location.pathname;

    if (currentPath === '/pricing-page') {
      navigate('/');
    }

    scrollToElement('services');
  };

  const moveToAboutUs = () => {
    const currentPath = window.location.pathname;

    if (currentPath === '/pricing-page') {
      navigate('/');
    }

    scrollToElement('aboutUs');
  };

  return (
    <div className="navbar-section primary-background-color">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-brand mobile-logo" onClick={navigateToHome}>
            <img alt="icon here" src={Logo} />
          </div>

          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <div
              className="navbar-brand me-auto desktop-logo"
              onClick={navigateToHome}
              style={{ cursor: 'pointer' }}
            >
              <img alt="icon here" src={Logo} />
            </div>
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="nav-item">
                <span
                  className="nav-link"
                  onClick={moveToAboutUs}
                  style={{ cursor: 'pointer' }}
                >
                  About Us
                </span>
              </li>
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <span className="nav-link" onClick={moveToServices}>
                  Services
                </span>
              </li>
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <span className="nav-link" onClick={navigateToPricingPage}>
                  Pricing
                </span>
              </li>
              {!user || user?.guest ? (
                <>
                  <li className="nav-item">
                    <a className="nav-link" href="#" onClick={openLogin}>
                      Login
                    </a>
                  </li>
                  <li className="nav-item">
                    <span className="nav-link" onClick={openSignup}>
                      Signup
                    </span>
                  </li>
                </>
              ) : null}
              <li className="nav-item try-probe-digital-btn">
                <a
                  className="nav-link"
                  onClick={checkUserLogin}
                  style={{ cursor: 'pointer' }}
                >
                  Try Judgments & Fines
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
