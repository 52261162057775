import React, { useContext, useEffect } from 'react';
import './CorporateLinksGraphRightSidebar.css';
import CorporateExploreInsightOverview from '../../../pages/CorporateExploreInsight/CorporateExploreInsightOverview';
import { AppContext } from '../../../context/Context';
import { capitalizeFirstLetter } from '../../../utils/helper';
import CEIPersonInsights from '../../../pages/CorporateExploreInsight/CEIPersonInsights/CEIPersonInsights';
import loadingGif from '../../../assets/images/loading.gif';
import CEIPersonInsightsLatestOverview from '../../../pages/CorporateExploreInsight/CEIPersonInsightsLatest/CEIPersonInsightsLatestOverview';
const CorporateLinksGraphRightSidebar = ({
  rightSidebarOpen,
  toggleRightSidebar,
  selectedCompany,
  accountInfo,
  selectedOfficer,
  isInfoLoading,
}) => {
  const { clGraph, setClGraph } = useContext(AppContext);

  const conditionalRender = (selectedCompany, selectedOfficer) => {
    if (selectedCompany) {
      return (
        <CorporateExploreInsightOverview
          selectedCompany={selectedCompany}
          accountInfo={accountInfo}
          forceMobileView={true}
        />
      );
    }

    if (selectedOfficer) {
      return (
        <CEIPersonInsightsLatestOverview
          selectedOfficer={selectedOfficer}
          forceMobileView={true}
        />
      );
    }
  };

  return (
    <div className="corporate-links-graph-right-sidebar-mian">
      <div className="corporate-links-graph-right-sidebar-mian-cross">
        <h5>{capitalizeFirstLetter(clGraph?.clickedNode?.entityType)}</h5>
        <button
          className="corporate-links-graph-right-sidebar-close-btn"
          onClick={toggleRightSidebar}
        >
          &times;
        </button>
      </div>

      {isInfoLoading ? (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={loadingGif} alt="Loading..." width={100} height={100} />
        </div>
      ) : (
        <div>{conditionalRender(selectedCompany, selectedOfficer)}</div>
      )}
    </div>
  );
};

export default CorporateLinksGraphRightSidebar;
