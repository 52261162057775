import React, { useState } from 'react';
import CLGrapgLeftSidebarItem from '../CLGrapgLeftSidebarItem/CLGrapgLeftSidebarItem';
import './CLGrapgLeftSidebarItemHolder.css';
import CorporateLinksGraphLeftSidebar from '../../CorporateLinksGraphLeftSidebar/CorporateLinksGraphSidebar';

function CLGrapgLeftSidebarItemHolder({
  setLeftSideButtonType,
  isLoadingShareholders,
}) {
  // Set the first item as active by default
  const [activeItem, setActiveItem] = useState('Officers & Companies');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const temperatureIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#007BFF"
      width="20px"
      height="20px"
    >
      <circle cx="12" cy="12" r="10" />
    </svg>
  );

  const items = [
    { text: 'Shareholders' },
    { text: 'Highlight Searched Entites' },
    { text: 'Show Only Relationship' },
    { text: 'Officers & Companies' },
    { text: 'Only Companies' },
    { text: 'Only Officers' },
  ];

  const handleItemClick = text => {
    setActiveItem(text);
    setLeftSideButtonType(text);
    if (window.innerWidth <= 768) {
      setSidebarOpen(true);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div>
      <CorporateLinksGraphLeftSidebar
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        activeItem={activeItem}
      />

      <div className="corporate-links-graph-left-sidebar-main">
        {items.map(item => (
          <CLGrapgLeftSidebarItem
            key={item.text}
            icon={temperatureIcon}
            text={item.text}
            isActive={activeItem === item.text}
            onClick={() => handleItemClick(item.text)}
            isLoadingShareholders={isLoadingShareholders}
          />
        ))}
      </div>
    </div>
  );
}

export default CLGrapgLeftSidebarItemHolder;
