import React, { useState, useRef, useEffect } from 'react';
import './CLSearchInput.css'; // Import your CSS styles
import search from './../../../assets/images/search.png';
import loadingGif from '../../../assets/images/loading.gif';
const CLSearchInput = ({ nodes, onSuggestionSelect, isNodeSearching }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsClicked, setSuggestionsClicked] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef]);

  useEffect(() => {
    if (inputValue) {
      const filteredSuggestions = nodes
        .filter(
          node =>
            node?.id?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
            node?.entityName?.toLowerCase()?.includes(inputValue?.toLowerCase())
        )
        .slice(0, 5);
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [inputValue, nodes]);

  const handleIconClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleInputChange = e => {
    setInputValue(e.target.value);
    setSuggestionsClicked(false);
  };

  const handleSuggestionClick = suggestion => {
    setSuggestionsClicked(true);
    setInputValue(suggestion.entityName || suggestion.id);
    setIsExpanded(false);
    onSuggestionSelect(suggestion);
  };

  return (
    <>
      <div className="corporete-links-Search-header">
        <div className="logo">
          <a href="https://probe-testing.web.app/">
            <span className="corporete-links-Search-comapny-name">
              Prob Digital
            </span>
          </a>
        </div>
        <div
          ref={inputRef}
          className={`corporete-links-Search-search-container ${isExpanded ? 'expanded' : ''}`}
        >
          <input
            type="text"
            name="q"
            autoComplete="off"
            id="search-q"
            className="corporete-links-Search-search-input"
            placeholder="Search by ID or Name…"
            value={inputValue}
            onChange={handleInputChange}
            onFocus={() => setIsExpanded(true)}
          />
          <button
            className="search-icon"
            type="button"
            onClick={handleIconClick}
          >
            {isNodeSearching ? (
              <img src={loadingGif} alt="Loading..." />
            ) : (
              <img src={search} alt="Search Icon" />
            )}
          </button>
          {suggestions.length > 0 && !suggestionsClicked && (
            <ul
              className="suggestions-list list-group"
              style={{
                position: 'absolute',
                zIndex: 10,
                width: '100%',
                maxHeight: '200px',
                overflowY: 'auto',
                top: '100%',
                left: 0,
              }}
            >
              {suggestions.map(suggestion => (
                <li
                  key={suggestion.id}
                  className="list-group-item list-group-item-action"
                  onClick={() => handleSuggestionClick(suggestion)}
                  style={{ cursor: 'pointer' }}
                >
                  {suggestion.entityName || suggestion.id}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default CLSearchInput;
