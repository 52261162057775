import './CorporateExplorerGrid.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import {
  corporateExplorerOfficerInfo,
  getCompanyInfo,
} from '../../../api/corporateExplorer/corporateExplorer';
import CompanyInfoModal from '../CompanyInfoModal/CompanyInfoModal';
import {
  findAccountsDataByCompanyNumber,
  processCompanyName,
} from '../../../utils/helper';
import QuickView from '../../QuickView/QuickView';
import { AppContext } from '../../../context/Context';

function CorporateExplorerGrid({
  filteredData,
  setFilteredData,
  hiddenColumns = [],
  showColumns = [],
  paginationFrom,
  showCompanyInfoModal,
  setShowCompanyInfoModal,
  toggleCompanyInfoModal,
  accountsData,
  setAccountsData,
}) {
  const { setFinancialAccountsProcess } = useContext(AppContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [companyInfo, setCompanyInfo] = useState(null);
  const [selectedCompanyInfo, setSelectedCompanyInfo] = useState(null);
  const [hoveredCompanyInfo, setHoveredCompanyInfo] = useState([]);
  const [singleAccountsInfo, setSingleAccountsInfo] = useState([]);

  const spanRef = useRef(null);

  const getContentWidth = content => {
    if (content) {
      const tempSpan = document.createElement('span');
      tempSpan.innerText = content;
      tempSpan.style.visibility = 'hidden';
      tempSpan.style.position = 'absolute';
      tempSpan.style.whiteSpace = 'wrap';
      document.body.appendChild(tempSpan);
      const contentWidth = tempSpan.offsetWidth;
      document.body.removeChild(tempSpan);
      return content.split('').length;
    }
    return 0;
  };

  const calculateXOffset = contentWidth => {
    const additional = 7.14 * contentWidth + 85.43;
    return contentWidth + additional;
  };

  const handleMouseEnter = (event, companyName, accountsData, rowData) => {
    setHoveredCompanyInfo(rowData);
    let account = findAccountsDataByCompanyNumber(
      rowData['company_number'],
      accountsData
    );
    setSingleAccountsInfo(account);
    const rect = event.currentTarget.getBoundingClientRect();
    const pageHeight = document.documentElement.scrollHeight;
    const pageWidth = document.documentElement.scrollWidth;
    setShowTooltip(true);

    let contentWidth = getContentWidth(companyName);

    const xOffset = calculateXOffset(contentWidth);
    const yOffset = 80; // Height or clearance above the tooltip

    // Ensure the tooltip does not go off the left side of the viewport
    const calculatedX = xOffset;
    let posX = calculatedX > 0 ? calculatedX : 10; // Provide a minimal left margin if overlap occurs

    // Adjust Y to not go off the top of the viewport
    const calculatedY = rect.top + window.scrollY - yOffset;
    let posY = calculatedY > 0 ? calculatedY : 10; // Provide a minimal top margin if overlap occurs
    if (pageHeight - posY <= 400) {
      posY = calculatedY - 200;
    }

    setTooltipPosition({
      x: posX,
      y: posY,
    });
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  // Combine showColumns with tableHeaders, handling missing keys gracefully
  const allHeaders = showColumns?.map(col => ({
    key: col.key,
    header: col.header || col.key, // col.key as fallback if no header provided
    noHeader: col.no_header,
  }));

  // Filter headers based on hiddenColumns
  const visibleHeaders = allHeaders.filter(
    header => !hiddenColumns.includes(header.key) && !header.noHeader
  );

  const displayedData = filteredData?.map(dataObject => {
    const rowData = {};
    showColumns.forEach(column => {
      const { key, header, no_header } = column;

      let value = dataObject?._source?.[key]; // Use optional chaining for safety

      // Handle nested structures (e.g., accounts.last_accounts.type)
      const keyParts = key.split('.');
      if (keyParts.length > 1) {
        let currentObject = dataObject?._source;
        for (const keyPart of keyParts) {
          currentObject = currentObject?.[keyPart];
          if (!currentObject) {
            value = undefined; // Set to undefined if a nested key is missing
            break;
          } else {
            value = currentObject;
          }
        }
      }

      if (!no_header) {
        rowData[header] = value;
      } else {
        rowData[header] = null; // Set to null to avoid rendering in the table cell
        rowData[no_header] = value; // Assign value to the no_header key for span
      }
    });
    return rowData;
  });

  const getStatusStyle = status => {
    let style = {
      borderRadius: '4px',
      padding: '1px 5px',
      fontSize: '11px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'inline-block',
      color: '#fff',
      whiteSpace: 'nowrap',
      background: '',
    };

    if (status === 'active' || status?.includes('active')) {
      style.background = '#16a086'; // Green color for active status
    } else if (status === 'liquidation') {
      style.background = '#cc5834'; // Red color for reactive status
    } else if (status === 'dormant') {
      style.background = '#8c8c8c'; // #8c8c8c color for reactive status
    } else if (status === 'dissolved') {
      style.background = '#8B0000'; // #8c8c8c color for reactive status
    } else if (status === 'in administration' || status === 'administration') {
      style.background = '#cc5834';
    }

    return style;
  };

  const processRegisteredOfficeAddress = rowData => {
    const {
      registered_office_address: {
        address_line_1: addressOne = '',
        address_line_2: addressTwo = '',
        locality = '',
        postal_code: postalCode = '',
        country = '',
      } = {},
    } = rowData;

    // Create an array of the address components
    const addressComponents = [
      addressOne,
      addressTwo,
      locality,
      postalCode,
      country,
    ];

    // Filter out null, undefined, and empty string values
    const filteredComponents = addressComponents.filter(component => component);

    // Join the filtered components with ', ' separator
    return filteredComponents.join(', ');
  };

  const processRowsCells = (header, rowData) => {
    if (header.header === 'Name') {
      // Modify style for the "Name" header
      return (
        <>
          <div
            style={{
              color: '#3360a9',
              fontWeight: '600',
              cursor: 'pointer',
              display: 'inline-block',
            }}
            className="corporate-explorer-grid-name-header"
            onClick={() => openCompanyDetailModal(rowData)}
            onMouseEnter={event =>
              handleMouseEnter(
                event,
                processCompanyName(rowData[header.header]),
                accountsData,
                rowData
              )
            }
            onMouseLeave={handleMouseLeave}
          >
            <span>{processCompanyName(rowData[header.header])}</span>
          </div>
          <div style={{ color: '#5c5c5c', fontSize: '11px' }}>
            {processRegisteredOfficeAddress(rowData)}
          </div>
        </>
      );
    }

    if (header.header === 'Status') {
      // Modify style for the "Name" header
      const status = rowData[header.header]?.toLowerCase();
      const statusStyle = getStatusStyle(status);

      return (
        <>
          <div style={statusStyle}>
            {rowData[header.header]?.toUpperCase().replace('IN', '').trim()}
          </div>
        </>
      );
    }

    if (header.header === 'Sic Codes') {
      return (
        <div style={{ color: '#5c5c5c' }}>
          {rowData[header?.header]?.map((item, index) => (
            <div key={index}>
              {item?.charAt(0)?.toUpperCase() + item?.slice(1)}
            </div>
          ))}
        </div>
      );
    }

    if (header.header === 'Can File') {
      return (
        <div style={{ color: '#5c5c5c' }}>
          {rowData[header.header] ? (
            <div style={{ color: '#16a086' }}>True</div>
          ) : (
            <div style={{ color: '#8B0000' }}>False</div>
          )}
        </div>
      );
    }

    return <div>{rowData[header.header]}</div>;
  };

  const openCompanyDetailModal = async data => {
    setFinancialAccountsProcess({
      graphData: { isLoading: false, data: null },
      nonGraphData: { isLoading: false, data: null },
    });
    toggleCompanyInfoModal();
    if (!data) return;
    let companyNumber = data.company_number;
    let companyInfoRes = await getCompanyInfo(companyNumber);
    setCompanyInfo(companyInfoRes);
    setSelectedCompanyInfo(data);
  };

  useEffect(() => {
    if (!showCompanyInfoModal) {
      setCompanyInfo(null);
      setSelectedCompanyInfo(null);
    }
  }, [showCompanyInfoModal]);

  return (
    <div>
      <div className="corporate-explorer-grid">
        <table id="customers" className="corporate-explorer-grid-table">
          <tr>
            <th></th>
            {visibleHeaders?.map(header => (
              <th
                className="corporate-explorer-grid-table-guide-row"
                key={header.key}
              >
                {header.header}
              </th>
            ))}
            <th className="corporate-explorer-grid-table-guide-column">+</th>
          </tr>
          {displayedData?.map((rowData, index) => (
            <tr key={index} className="corporate-explorer-grid-table-guide-row">
              <td
                key={`row-number-${index}`}
                className="corporate-explorer-grid-table-guide-column"
              >
                {paginationFrom + index + 1} {/* Display 1-based row numbers */}
                <div style={{ fontSize: '15px' }}>
                  <InsertDriveFileIcon
                    fontSize="inherit"
                    color="action"
                    style={{ cursor: 'pointer' }}
                    onClick={() => openCompanyDetailModal(rowData)}
                  />
                </div>
              </td>
              {visibleHeaders?.map(header => (
                <td
                  key={header.key}
                  style={
                    header.key === 'Name'
                      ? { display: 'flex', flexDirection: 'column' }
                      : null
                  }
                  className={`corporate-explorer-grid-table-guide-row ${
                    header.header === 'Name'
                      ? 'corporate-explorer-grid-table-guide-row-vertical-align-top'
                      : ''
                  }`}
                >
                  {processRowsCells(header, rowData)}
                </td>
              ))}
            </tr>
          ))}
        </table>
      </div>
      {showTooltip && singleAccountsInfo && hoveredCompanyInfo && (
        <QuickView
          visible={showTooltip}
          posX={tooltipPosition.x}
          posY={tooltipPosition.y}
          singleAccountsInfo={singleAccountsInfo}
          hoveredCompanyInfo={hoveredCompanyInfo}
        />
      )}
      <CompanyInfoModal
        showCompanyInfoModal={showCompanyInfoModal}
        setShowCompanyInfoModal={setShowCompanyInfoModal}
        toggleCompanyInfoModal={toggleCompanyInfoModal}
        companyInfo={companyInfo}
        selectedCompanyInfo={selectedCompanyInfo}
      />
    </div>
  );
}

export default CorporateExplorerGrid;
