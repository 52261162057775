export default class LocalStorageService {
  // Save an item to local storage
  static setItem(key, value) {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error('Error saving to local storage', error);
    }
  }

  // Get an item from local storage
  static getItem(key) {
    try {
      const serializedValue = localStorage.getItem(key);
      return serializedValue === null ? null : JSON.parse(serializedValue);
    } catch (error) {
      console.error('Error reading from local storage', error);
      return null;
    }
  }

  // Remove an item from local storage
  static removeItem(key) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing from local storage', error);
    }
  }

  static clearLocalStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  static setupPathForNavigation(value) {
    LocalStorageService.setItem('navigation-path', value);
  }
}
