class URLService {
  static getParams() {
    return new URLSearchParams(window.location.search);
  }

  static setParam(key, value) {
    const params = this.getParams();
    params.set(key, value);
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }

  static removeParam(key) {
    const params = this.getParams();
    params.delete(key); // Remove the parameter
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }

  static getParam(key) {
    const params = this.getParams();
    return params.get(key);
  }
}

export default URLService;
