import React from 'react';
import './CEIinsightResultsCard.css';
import { nationalityToFlag, statusDiv } from '../../../utils/helper';
import { getMonthName } from '../../../utils/common';

const InsightCard = ({ officer }) => {
  const isNeedActive = !officer.resigned_on && officer.need >= 20;

  const formatCompanies = officers => {
    if (officers.length === 0) return null;

    if (officers.length === 1) {
      return officers[0]?.company_name; // Only one officer
    } else {
      return (
        <>
          {officers[0]?.company_name}{' '}
          {/* Display only the first officer's name */}
          &nbsp;
          <a href="#">
            & {officers.length - 1} others{' '}
            {/* Display how many others there are */}
          </a>
        </>
      );
    }
  };

  return (
    <div className="corporate-insights-result-card">
      <div className="corporate-insights-result-card-content">
        <div className="corporate-insights-result-card-content-header">
          <h6>{officer.name}</h6>
        </div>
        <div className="corporate-insights-result-card-body">
          <div className="corporate-insights-result-card-info-row">
            <span className="corporate-insights-result-card-label">Status</span>
            <span
              className={`corporate-insights-result-card-value ${isNeedActive ? 'corporate-insights-result-card-status-active' : 'corporate-insights-result-card-status-inactive'}`}
            >
              {officer?.resigned_on
                ? statusDiv('resigned')
                : statusDiv('active')}
              {isNeedActive && (
                <span className="need-indicator"> (Need 20%)</span>
              )}
            </span>
          </div>
          <div className="corporate-insights-result-card-info-row">
            <span className="corporate-insights-result-card-label">Role</span>
            <span className="corporate-insights-result-card-value">
              {officer?.officer_role}
            </span>
          </div>
          <div className="corporate-insights-result-card-info-row">
            <span className="corporate-insights-result-card-label">
              Nationality
            </span>
            <span className="corporate-insights-result-card-value">
              {nationalityToFlag[
                officer?.nationality?.toUpperCase().replace(' ', '')
              ] && (
                <img
                  src={
                    nationalityToFlag[
                      officer?.nationality?.toUpperCase().replace(' ', '')
                    ]
                  }
                  alt={`${officer.nationality} flag`}
                  style={{
                    width: '15px',
                    height: '10px',
                  }}
                />
              )}{' '}
              {officer.nationality && (
                <span>
                  {officer?.nationality?.toUpperCase().replace(' ', '')} , {''}{' '}
                </span>
              )}
              {officer?.date_of_birth?.month &&
                officer?.date_of_birth?.year && (
                  <span>
                    Born in&nbsp;
                    {getMonthName(officer?.date_of_birth?.month)}
                    &nbsp;
                    {officer.date_of_birth.year}
                  </span>
                )}
            </span>
          </div>
          <div className="corporate-insights-result-card-info-row">
            <span className="corporate-insights-result-card-label">
              Occupations
            </span>
            <span className="corporate-insights-result-card-value">
              {officer.occupation}
            </span>
          </div>
          {officer?.pcompany_list?.length > 0 && (
            <div className="corporate-insights-result-card-info-row">
              <span className="corporate-insights-result-card-label">
                Companies
              </span>
              <span className="corporate-insights-result-card-value result-link">
                {formatCompanies(officer?.pcompany_list)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InsightCard;
