import PostSignup from './pages/Home/PostSignup.jsx';
import PostLogin from './pages/Home/PostLoginIn.jsx';
import ProfileDashboard from './pages/Home/ProfileDashboard.jsx';
import SearchOnPerson from './pages/JudgementChecker/CheckReviewPerson/SearchOnPerson/SearchOnPerson.js';
import SummaryReport from './components/SummaryReport';
import SearchResult from './components/SearchResult';
import AccountsUsage from './pages/AccountsUsage/AccountsUsage.js';
import ReportsPage from './pages/ReportsPage/ReportsPage.jsx';
import CustomerAdminScreen from './pages/CustomerAdminScreen/CustomerAdminScreen.js';
import BlogDashboard from './pages/Blogs/BlogDashboard.jsx';
import PostBlogs from './pages/Blogs/Post/PostBlogs';
import ViewBlogs from './pages/Blogs/View/ViewBlogs';
import SpecificBlog from './pages/Blogs/Specific/SpecificBlog';
import CEIDashboard from './pages/CorporateExploreInsight/CEIDashboard.jsx';
import CorporateExploreInsight from './pages/CorporateExploreInsight/CorporateExploreInsight.jsx';
import CEIPersonInsights from './pages/CorporateExploreInsight/CEIPersonInsights/CEIPersonInsights.jsx';
import CLDashboard from './pages/CorporateLinks/CLDashboard/CLDashboard.jsx';
import CESearchEntities from './pages/CorporateLinks/CESearchEntities/CESearchEntities.jsx';
import CorporateLinksGraph from './pages/CorporateLinks/CoporateLinksGraph/CoporateLinksGraph.jsx';
import CEDashboard from './pages/CorporateExplorer/CEDashboard.jsx';
import CorporateExplorer from './pages/CorporateExplorer/CorporateExplorer.jsx';
import CCJDashboard from './pages/JudgementChecker/CCJDashboard/CCJDashboard.jsx';
import JudgementChecker from './pages/JudgementChecker/JudgementChecker.js';
import JudgementCheckerBusiness from './pages/JudgementChecker/CheckReviewBusiness/JudgementCheckerBusiness/JudgementCheckerBusiness';
import JudgementCheckerPerson from './pages/JudgementChecker/CheckReviewPerson/JudgementCheckerPerson/JudgementCheckerPerson';
import CheckReviewPerson from './pages/JudgementChecker/CheckReviewPerson/CheckReviewPerson';
import CheckReviewBusiness from './pages/JudgementChecker/CheckReviewBusiness/CheckReviewBusiness';
import JudgmentCheckerReport from './pages/JudgementChecker/JudgmentCheckerReport/JudgmentCheckerReport';
import JudgementCheckerYourDetail from './pages/JudgementChecker/JudgementCheckerYourDetail/JudgementCheckerYourDetail.jsx';
import TermsAndConditions from './pages/JudgementChecker/TermsAndPolicy/TermsAndConditions/TermsAndConditions.jsx';
import PrivacyPolicy from './pages/JudgementChecker/TermsAndPolicy/PrivacyPolicy/PrivacyPolicy.jsx';
import FODashboard from './pages/ForeignUKOwnership/FODashboard.jsx';
import ForegnUKOwnership from './pages/ForeignUKOwnership/ForegnUKOwnership';
import BIDashboard from './pages/BackgroundInvestigation/BIDashboard.jsx';
import BackgroundInvestigation from './pages/BackgroundInvestigation/BackgroundInvestigation';
import FBFDashboard from './pages/FeedbackForm/FBFDashboard.jsx';
import FeedbackForm from './pages/FeedbackForm/FeedbackForm.js';

export const routesConfig = [
  { path: '/sign-up-post', element: <PostSignup />, name: 'Sign Up' },
  { path: '/login-post', element: <PostLogin />, name: 'Login' },
  {
    path: '/profile',
    element: <ProfileDashboard />,
    name: 'Profile Dashboard',
    children: [
      {
        path: 'search-on-person',
        element: <SearchOnPerson />,
        name: 'Search on Person',
      },
      {
        path: 'summary-report',
        element: <SummaryReport />,
        name: 'Summary Report',
      },
      {
        path: 'search-result',
        element: <SearchResult />,
        name: 'Search Result',
      },
      {
        path: 'accounts-usage',
        element: <AccountsUsage />,
        name: 'Accounts Usage',
      },
      {
        path: 'judgement-checker-reports',
        element: <ReportsPage />,
        name: 'Judgement Checker Reports',
      },
      {
        path: 'customer-admin',
        element: <CustomerAdminScreen />,
        name: 'Customer Admin',
        allowedRoles: ['admin'],
      },
    ],
  },
  {
    path: '/blog-dashboard',
    element: <BlogDashboard />,
    name: 'Blog Dashboard',
    children: [
      { path: 'post', element: <PostBlogs />, name: 'Post Blog' },
      { path: 'view', element: <ViewBlogs />, name: 'View Blogs' },
      { path: ':id', element: <SpecificBlog />, name: 'Specific Blog' },
    ],
  },
  {
    path: '/cei-dashboard',
    element: <CEIDashboard />,
    name: 'CEI Dashboard',
    children: [
      {
        path: 'cei-corporate-explore-insight',
        element: <CorporateExploreInsight />,
        name: 'Corporate Explore Insight',
      },
      {
        path: 'cei-person-insights',
        element: <CEIPersonInsights />,
        name: 'CEI Person Insights',
      },
    ],
  },
  {
    path: '/cl-dashboard',
    element: <CLDashboard />,
    name: 'CL Dashboard',
    children: [
      {
        path: 'search-entites',
        element: <CESearchEntities />,
        name: 'Corporate Links',
      },
      {
        path: 'cl-graph',
        element: <CorporateLinksGraph />,
        name: 'Corporate Links Graph',
      },
    ],
  },
  {
    path: '/ce-dashboard',
    element: <CEDashboard />,
    name: 'CE Dashboard',
    children: [
      {
        path: 'ce-corporate-explorer',
        element: <CorporateExplorer />,
        name: 'Corporate Explorer',
      },
    ],
  },
  {
    path: '/ccj-dashboard',
    element: <CCJDashboard />,
    name: 'CCJ Dashboard',
    children: [
      {
        path: 'judgement-checker',
        element: <JudgementChecker />,
        name: 'Judgments & Fines',
      },
      {
        path: 'judgement-checker-business',
        element: <JudgementCheckerBusiness />,
        name: 'Judgments & Fines - Business',
      },
      {
        path: 'judgement-checker-person',
        element: <JudgementCheckerPerson />,
        name: 'Judgments & Fines - Person',
      },
      {
        path: 'check-review-person',
        element: <CheckReviewPerson />,
        name: 'Judgments & Fines - Person',
      },
      {
        path: 'check-review-business',
        element: <CheckReviewBusiness />,
        name: 'Judgments & Fines - Business',
      },
      {
        path: 'report',
        element: <JudgmentCheckerReport />,
        name: 'Judgments & Fines Report',
      },
      {
        path: 'judgement-checker-your-detail',
        element: <JudgementCheckerYourDetail />,
        name: 'Your Details',
      },
      {
        path: 'terms-and-conditions',
        element: <TermsAndConditions />,
        name: 'Terms and Conditions',
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicy />,
        name: 'Privacy Policy',
      },
    ],
  },
  {
    path: '/fo-dashboard',
    element: <FODashboard />,
    name: 'FO Dashboard',
    children: [
      {
        path: 'foregn-uk-ownership',
        element: <ForegnUKOwnership />,
        name: 'Foreign UK Ownership',
      },
    ],
  },
  {
    path: '/bi-dashboard',
    element: <BIDashboard />,
    name: 'BI Dashboard',
    children: [
      {
        path: 'background-investigation',
        element: <BackgroundInvestigation />,
        name: 'Background Investigation',
      },
    ],
  },
  {
    path: '/fbf-dashboard',
    element: <FBFDashboard />,
    name: 'FBF Dashboard',
    children: [
      {
        path: 'feedbackform',
        element: <FeedbackForm />,
        name: 'Feedback Form',
      },
    ],
  },
];
