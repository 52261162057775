import React from 'react';
import './CEIPersonInsightsCompanies.css';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {
  getStatusStringFromChar,
  getStatusStyle,
} from '../../../../utils/helper';
import CompaniesLogo from '../../../../assets/images/CompaniesLogo.png';
import { COMPANIES_HOUSE_DETAIL } from '../../../../utils/constants';

const CEIPersonInsightsCompanies = ({ companies }) => {
  const onClickCompaniesLogo = selectedCompany => {
    if (!selectedCompany?.company_number) {
      return;
    }
    const url = COMPANIES_HOUSE_DETAIL(selectedCompany?.company_number);
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <div className="corporate-explore-insight-companies-container">
      <h2 className="corporate-explore-insight-companies-title">Companies</h2>
      <table className="corporate-explore-insight-companies-table">
        <thead>
          <tr>
            <th>Company</th>
            {/* <th>Occupation</th>
            <th>Position</th> */}
            <th>Appointed</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {companies?.length > 0 ? (
            companies?.map((company, index) => (
              <tr
                key={index}
                className="corporate-explore-insight-companies-container-row"
              >
                <td className="corporate-explore-insight-company-name">
                  <div>
                    <div>
                      {/* <span className="corporate-explore-insight-company-icon"></span> */}
                      <a
                        className="corporate-explore-insight-company-name-link"
                        href="/"
                      >
                        {company?.name}
                      </a>
                    </div>
                    <div>
                      {' '}
                      <span>
                        {company?.occupation} {company?.position}
                      </span>
                    </div>
                  </div>
                </td>
                {/* <td>{company.occupation}</td>
                <td>{company.position}</td> */}
                <td>{company?.appointed}</td>
                <td>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span
                      style={getStatusStyle(company?.status?.toLowerCase())}
                    >
                      {getStatusStringFromChar(company?.status)}
                    </span>
                  </div>
                  <div className="corporate-explore-insight-companies-network-buttons">
                    <div>
                      <a
                        href={`https://www.google.com/search?q=${company?.name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LanguageIcon style={{ width: '20px' }} />
                      </a>
                    </div>
                    <div>
                      <a
                        href={`https://www.linkedin.com/search/results/all/?keywords=${company?.name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <LinkedInIcon style={{ width: '20px' }} />
                      </a>
                    </div>
                    <div>
                      <img
                        src={CompaniesLogo}
                        alt="Companies Logo"
                        width="20"
                        height="20"
                        onClick={() => onClickCompaniesLogo(company)}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No companies available</td>
            </tr>
          )}
        </tbody>
      </table>
      <a href="/" className="corporate-explore-insight-view-all-companies">
        VIEW ALL {companies?.length} COMPANIES
      </a>
    </div>
  );
};

export default CEIPersonInsightsCompanies;
