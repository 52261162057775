import React, { useContext, useEffect, useState } from 'react';
import ServicesTable from '../../components/ServicesTable';
import headerLine from '../../assets/images/icons/header-line.svg';
import HeaderDropdown from '../../components/HeaderDropdownNative';
import { AppContext } from '../../context/Context';
import '../JudgementChecker/CheckReviewPerson/SearchOnPerson/SearchOnPerson.css';
import './AccountsUsage.css';
import { getCurrentMonth } from '../../utils/helper';
import { getUserById } from '../../api/outseta/outseta';
import { useLoading } from '../../context/LoadingContext/LoadingContext';
import { triggerGoogleAnalyticsEvent } from '../../utils/googleAnalyticsEvent';
import AccountsProfileDetails from '../../components/AccountsProfileDetails/AccountsProfileDetails';

function AccountsUsage() {
  const { setIsLoading } = useLoading();
  const { user } = useContext(AppContext);
  const [hide] = useState(true);
  const [apiCallsEver, setApiCallsEver] = useState();
  const [apiCallsCurrentMonth, setApiCallsCurrentMonth] = useState();

  const usageUpdate = async () => {
    try {
      setIsLoading(true);
      let month = getCurrentMonth();
      const { data } = await getUserById(user.FirebaseUser?.user_id);

      if (!data) {
        setIsLoading(false);
        return;
      }

      let { usage } = data;

      if (!usage) {
        setApiCallsCurrentMonth(0);
        setApiCallsEver(0);
        setIsLoading(false);
        return;
      }

      setApiCallsCurrentMonth(
        usage?.companiesHouse_api_usage.monthly[month] +
          usage?.openCorporates_api_usage.monthly[month]
      );
      setApiCallsEver(
        usage?.companiesHouse_api_usage.total_usage +
          usage?.openCorporates_api_usage.total_usage
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const main = async () => {
      await usageUpdate();
    };
    main();
    triggerGoogleAnalyticsEvent('page_visits', 'account_usage');
  }, []);

  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className="dashboard-content-div">
          <AccountsProfileDetails />

          <ServicesTable
            apiCallsEver={apiCallsEver}
            apiCallsCurrentMonth={apiCallsCurrentMonth}
            customerDetail={user}
          />

          {!hide && (
            <>
              <div className="heading-1 mt-4">
                <img src={headerLine} alt="icon here" /> Corporate Links
              </div>
              <div className="corporate-links-section">
                <div className="inner-card">
                  <p className="heading heading-2 dark-heading-color">
                    No. of API used to date
                  </p>
                  <p className="count button-text  dark-text-color mb-0">
                    {apiCallsEver}
                  </p>
                </div>
                <div className="inner-card">
                  <p className="heading heading-2 dark-heading-color">
                    No of API used this month
                  </p>
                  <p className="count button-text  dark-text-color mb-0">
                    {apiCallsCurrentMonth}
                  </p>
                </div>
                <div className="inner-card">
                  <p className="heading heading-2 dark-heading-color">
                    No of API used this month
                  </p>
                  <p className="count button-text  dark-text-color mb-0">
                    *Unlimited in beta release
                  </p>
                </div>
              </div>
              <div className="heading-1 mt-4">
                <img src={headerLine} alt="icon here" /> Foreign Ownership of UK
                Companies
              </div>
              <div className="corporate-links-section">
                <div className="inner-card">
                  <p className="heading heading-2 dark-heading-color">
                    No. of API used to date
                  </p>
                  <p className="count button-text  dark-text-color mb-0">256</p>
                </div>
                <div className="inner-card">
                  <p className="heading heading-2 dark-heading-color">
                    No of API used this month
                  </p>
                  <p className="count button-text  dark-text-color mb-0">34</p>
                </div>
                <div className="inner-card">
                  <p className="heading heading-2 dark-heading-color">
                    No of API used this month
                  </p>
                  <p className="count button-text  dark-text-color mb-0">
                    *Unlimited in beta release
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AccountsUsage;
