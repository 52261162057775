import React, { useEffect, useState, useContext } from 'react';
import LineHeader from '../../../assets/images/icons/header-line.svg';
import detailIcon from '../../../assets/images/icons/bx_detail.svg';
import referenceIcon from '../../../assets/images/icons/material-symbols_quick-reference-outline.svg';
import IDIcon from '../../../assets/images/icons/ant-design_number-outlined.svg';
import LinkIcon from '../../../assets/images/icons/ph_link.svg';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import '../../../components/CustomerTable.css';
import { getJudgementCheckerReports } from '../../../api/judgementChecker/judgementChecker';
import ReportModal from '../../../components/ReportModal/ReportModal';
import { downloadReport } from '../../../api/judgementChecker/judgementChecker';
import { useLoading } from '../../../context/LoadingContext/LoadingContext';
import { AppContext } from '../../../context/Context';
import Pagination from '../../../components/Pagination/Pagination';
import { formatDateFromString } from '../../../utils/helper';
import { determineCaseColor } from '../../../utils/helper';
import JudgementCheckerSquare from '../JudgmentCheckerReport/Report/JudgementCheckerSquare';

function JudgementCheckerReports({
  showHeader = true,
  userId = null,
  removePadding = false,
}) {
  const { setIsLoading } = useLoading();
  const [showModal, setShowModal] = useState(false);
  const [reports, setReports] = useState([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const { user } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handleDownload = async url => {
    setIsLoading(true);
    await downloadReport(url);
    setIsLoading(false);
  };

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  // Define an async arrow function
  const fetchReports = async currentPage => {
    try {
      setIsLoading(true);
      let response = await getJudgementCheckerReports(
        userId !== null ? userId : user?.FirebaseUser?.user_id,
        currentPage + 1,
        5
      );

      if (!response.success) {
        setIsLoading(false);
        return;
      }

      setReports(response.data);
      setTotalPages(response.totalPages);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      const apiCall = async () => {
        await fetchReports(currentPage);
      };

      apiCall();
    }
  }, [currentPage, userId]);

  return (
    <div style={{ width: '100%' }}>
      <div className="dashboard-section">
        <div className="main-div">
          {/* {showHeader && (
            <div className="header">
              <div className="header-heading dark-heading-color">Reports</div>
              <HeaderDropdown />
            </div>
          )} */}
          <div
            className="dashboard-content-div"
            style={
              removePadding
                ? { paddingLeft: '0px', paddingRight: '0px' }
                : undefined
            }
          >
            <div className="customerTable-info-section">
              <div className="header-section">
                <div className="body-text-normal dark-text-color">
                  <img src={LineHeader} alt="icon here" /> My Reports
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-custom">
                  <thead>
                    <tr>
                      <td scope="col">
                        <div className="thead-td-custom">
                          <img
                            className="img-thead"
                            src={detailIcon}
                            alt="icon here"
                          />
                          Generated on
                        </div>
                      </td>
                      <td scope="col">
                        <div className="thead-td-custom">
                          <img
                            className="img-thead"
                            src={detailIcon}
                            alt="icon here"
                          />{' '}
                          Entity searched
                        </div>
                      </td>
                      <td scope="col">
                        <div className="thead-td-custom">
                          <img
                            className="img-thead"
                            src={referenceIcon}
                            alt="icon here"
                          />{' '}
                          Reference
                        </div>
                      </td>
                      <td scope="col">
                        <div className="thead-td-custom">
                          <img
                            className="img-thead"
                            src={IDIcon}
                            alt="icon here"
                          />
                          Overall Result
                        </div>
                      </td>
                      <td scope="col">
                        <div className="thead-td-custom">
                          <img
                            className="img-thead"
                            src={IDIcon}
                            alt="icon here"
                          />{' '}
                          Search Type
                        </div>
                      </td>
                      <td scope="col">
                        <div className="thead-td-custom">
                          <img
                            className="img-thead"
                            src={LinkIcon}
                            alt="icon here"
                          />{' '}
                          Link
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {reports.length > 0 ? (
                      reports.map((report, index) => (
                        <tr key={index}>
                          <td className="body-text-bold">
                            {formatDateFromString(
                              report?.judgementDetail?.uniqueReferenceNumber
                            )}
                          </td>
                          <td className="body-text-bold">
                            {report?.judgementDetail?.judgmentCheckerType
                              ?.type === 'BusinessSearch'
                              ? report?.judgementDetail?.judgmentCheckerType
                                  .companyName
                              : report?.judgementDetail?.judgmentCheckerType
                                  ?.personInfo?.firstName +
                                ' ' +
                                report?.judgementDetail?.judgmentCheckerType
                                  ?.personInfo?.lastName}
                          </td>
                          <td className="body-text-bold">
                            {report?.judgementDetail?.uniqueReferenceNumber}
                          </td>
                          <td className="body-text-bold">
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '50%',
                              }}
                            >
                              <JudgementCheckerSquare
                                color={determineCaseColor(
                                  report.judgementDetail?.judgmentCheckerType
                                    ?.JudgementChecker
                                )}
                                width="12px"
                                height="12px"
                                marginTop="1%"
                              />
                            </div>
                          </td>

                          <td className="body-text-bold">
                            {report?.judgementDetail?.judgmentCheckerType?.type}
                          </td>
                          <td>
                            <span
                              className="body-text-bold"
                              style={{
                                color: '#0466D1',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                marginRight: '5px', // Adds space between the two links
                              }}
                              onClick={() => handleDownload(report?.link)}
                            >
                              Download
                            </span>
                            <span
                              className="body-text-bold"
                              style={{
                                color: '#0466D1',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                /* function to handle view */
                                setShowModal(!showModal);
                                setPdfUrl(report?.link);
                              }}
                            >
                              View PDF
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="body-text-bold">
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* pagination component  */}
            <div style={{ marginTop: '25px' }}></div>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>

      <div>
        <ReportModal
          showModal={showModal}
          setShowModal={setShowModal}
          pdfUrl={pdfUrl}
        />
      </div>
    </div>
  );
}

export default JudgementCheckerReports;
