import React, { useState, useEffect, useContext } from 'react';
import './CESAdvancedFilter.css';
import DateRangeSlider from './CESFilterSlider/CESFilterSlider';
import { AppContext } from '../../../../context/Context';

const CESAdvancedFilter = () => {
  const minDate = 1800;
  const maxDate = 2024;
  const { corporateLinksAdvanceFilters, setCorporateLinksAdvanceFilters } =
    useContext(AppContext);
  const [filters, setFilters] = useState({
    companyStatus: {
      active: false,
      dissolved: false,
    },
    companyType: '',
    locality: '',
    dateRange: [minDate, maxDate],
  });

  const handleCheckboxChange = e => {
    const { name, checked } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      companyStatus: {
        ...prevFilters.companyStatus,
        [name]: checked,
      },
    }));
  };

  const handleSelectChange = e => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleDateRangeChange = newRange => {
    setFilters(prevFilters => ({
      ...prevFilters,
      dateRange: newRange,
    }));
  };

  useEffect(() => {
    setCorporateLinksAdvanceFilters({
      ...corporateLinksAdvanceFilters,
      officerFilters: null,
      companyFilters: filters,
    });
  }, [filters]);

  return (
    <div className="ces-advanced-filter">
      <h3>Company Filters</h3>
      <div className="filter-grid-company">
        <div className="company-status">
          <span className="filter-title">Company Status:</span>
          <div className="checkbox-group">
            <label>
              <div style={{ display: 'flex' }}>
                <input
                  type="checkbox"
                  name="active"
                  checked={filters?.companyStatus?.active}
                  onChange={handleCheckboxChange}
                />
                Active
              </div>
            </label>
            <label>
              <div style={{ display: 'flex' }}>
                <input
                  type="checkbox"
                  name="dissolved"
                  checked={filters?.companyStatus?.dissolved}
                  onChange={handleCheckboxChange}
                />
                Dissolved
              </div>
            </label>
          </div>
        </div>

        <div className="filter-section">
          <span className="filter-title">Company Type:</span>
          <select
            name="companyType"
            value={filters?.companyType}
            onChange={handleSelectChange}
          >
            <option></option>
            <option>ltd.</option>
            <option>Partnership</option>
          </select>
        </div>

        <div className="filter-section">
          <span className="filter-title">Locality:</span>
          <select
            name="locality"
            value={filters?.locality}
            onChange={handleSelectChange}
          >
            <option></option>
            <option>Chesterfield</option>
            <option>Grimsby</option>
            <option>Milton Keynes</option>
            <option>London</option>
            <option>Leamington SPA</option>
            <option>Manchester</option>
            <option>Reading</option>
          </select>
        </div>
      </div>
      <div>
        <DateRangeSlider
          labelName={'Date of creation Range'}
          values={filters.dateRange}
          setValues={handleDateRangeChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  );
};

export default CESAdvancedFilter;
