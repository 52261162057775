import React from 'react';
import './OneFinalCheck.css';
import OneFinalCheckBox from './OneFinalCheckBox';

function OneFinalCheck({
  setPrivacyPolicy,
  setAvaibleViaTrust,
  setLinkedToSearch,
  avaibleViaTrust,
  linkedToSearch,
  privacyPolicy,
}) {
  const openTermsAndConditions = () => {
    openNewTab('/ccj-dashboard/terms-and-conditions');
  };

  const openPrivacyPage = () => {
    openNewTab('/ccj-dashboard/privacy-policy');
  };

  const openNewTab = url => {
    // Open a new tab with the specified URL
    window.open(url, '_blank');
  };

  return (
    <div>
      <span className="one_final_check_header">
        One final check - please confirm
      </span>
      <div>
        <div className="one_final_check_box_main_div_for_checkbox_content">
          <div className="one_final_check_box_content">
            I have read and agree to the{' '}
            <span
              style={{
                color: '#0d6efd',
                cursor: 'pointer',
                marginRight: '3px',
              }}
              onClick={openTermsAndConditions}
            >
              terms and conditions
            </span>
            and
            <span
              style={{ color: '#0d6efd', marginLeft: '4px', cursor: 'pointer' }}
              onClick={openPrivacyPage}
            >
              privacy policy
            </span>
          </div>
          <OneFinalCheckBox
            name={'privacy_policy'}
            setRadioValue={setPrivacyPolicy}
            defaultValue={privacyPolicy}
          />
        </div>

        <div className="probe_input_use_form_error_main_div_final_check">
          {!privacyPolicy ? (
            <div className="probe_input_use_form_error_main_div_final_check_error">
              Please confirm you have read and agree to the terms and conditions
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div className="one_final_check_box_main_div_for_checkbox_content">
          <div className="one_final_check_box_content">
            I understand that the details of who the money is owed to is not
            available via Probe Digital
          </div>
          <OneFinalCheckBox
            name={'avaible_via_trust'}
            setRadioValue={setAvaibleViaTrust}
            defaultValue={avaibleViaTrust}
          />
        </div>
        <div className="probe_input_use_form_error_main_div_final_check">
          {!avaibleViaTrust ? (
            <div className="probe_input_use_form_error_main_div_final_check_error">
              Please confirm you understand that claimant information is not
              available via Probe Digital
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div className="one_final_check_box_main_div_for_checkbox_content">
          <div className="one_final_check_box_content">
            I understand that any previous name or address will not be linked to
            my search
          </div>
          <OneFinalCheckBox
            name={'linked_to_search'}
            setRadioValue={setLinkedToSearch}
            defaultValue={linkedToSearch}
          />
        </div>
        <div className="probe_input_use_form_error_main_div_final_check">
          {!linkedToSearch ? (
            <div className="probe_input_use_form_error_main_div_final_check_error">
              Please confirm you understand that previous addresses are not
              searched
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OneFinalCheck;
