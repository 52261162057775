import React, { useState, useEffect, useContext } from 'react';
import './CESAdvancedFilterPerson.css';
import DateRangeSlider from '../CESAdvancedFilter/CESFilterSlider/CESFilterSlider';
import { AppContext } from '../../../../context/Context';

const CESAdvancedFilterPerson = () => {
  const minDate = 1800;
  const maxDate = 2024;
  const { corporateLinksAdvanceFilters, setCorporateLinksAdvanceFilters } =
    useContext(AppContext);
  // Initialize a single object to handle all values
  const [advanceFilterPerson, setAdvanceFilterPerson] = useState({
    nationality: '',
    occupation: '',
    positionRole: '',
    dateRange: [minDate, maxDate],
  });

  // Update handlers for each input type
  const handleSelectChange = e => {
    setAdvanceFilterPerson({
      ...advanceFilterPerson,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateRangeChange = newRange => {
    setAdvanceFilterPerson({
      ...advanceFilterPerson,
      dateRange: newRange,
    });
  };

  useEffect(() => {
    setCorporateLinksAdvanceFilters({
      ...corporateLinksAdvanceFilters,
      officerFilters: advanceFilterPerson,
    });
  }, [advanceFilterPerson]);

  return (
    <div className="ces-advanced-filter">
      <h3>People Filters</h3>
      <div className="filter-grid-person">
        <div className="company-status">
          <div className="filter-section">
            <span className="filter-title">Nationality:</span>
            <select
              name="nationality"
              value={advanceFilterPerson.nationality}
              onChange={handleSelectChange}
            >
              <option></option>
              <option>England</option>
              <option>British</option>
              <option>AFGHAN</option>
              <option>ROMANIAN</option>
              <option>ENGLISH</option>
              <option>Pakistani</option>
              <option>UKRAINIAN</option>
            </select>
          </div>
        </div>

        <div className="filter-section">
          <span className="filter-title">Occupation:</span>
          <select
            name="occupation"
            value={advanceFilterPerson.occupation}
            onChange={handleSelectChange}
          >
            <option></option>
            <option>BUSINESS PERSON</option>
            <option>MATHEMATICIAN</option>
            <option>OPERATIONS MANAGER</option>
            <option>COMPANY DIRECTOR</option>
            <option>DIRECTOR</option>
            <option>CONSULTANT</option>
            <option>Nurse</option>
          </select>
        </div>
        <div className="filter-section">
          <span className="filter-title">Officer Role:</span>
          <select
            name="positionRole"
            value={advanceFilterPerson.positionRole}
            onChange={handleSelectChange}
          >
            <option></option>
            <option>Current Director</option>
          </select>
        </div>
      </div>
      <div className="filter-section">
        <DateRangeSlider
          labelName={'Age Range'}
          values={advanceFilterPerson.dateRange}
          setValues={handleDateRangeChange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  );
};

export default CESAdvancedFilterPerson;
