import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { routesConfig } from '../../routeConfig';

const usePageTitle = () => {
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('Dashboard');

  useEffect(() => {
    const currentRoute = findRouteByPath(routesConfig, location?.pathname);

    if (currentRoute) {
      const title = currentRoute.name || 'Dashboard';
      setPageTitle(title);
    } else {
      setPageTitle('Dashboard');
    }
  }, [location]);

  return pageTitle;
};

const findRouteByPath = (routes, pathname) => {
  for (const route of routes) {
    const fullPath = route.path.startsWith('/') ? route.path : `${route.path}`;

    if (pathname === fullPath) {
      return route;
    }

    if (route.children) {
      const matchedChild = findRouteByPath(
        route.children,
        pathname.startsWith(fullPath)
          ? pathname.replace(fullPath, '').replace(/^\/+/, '')
          : pathname
      );
      if (matchedChild) {
        return matchedChild;
      }
    }
  }

  return null;
};

export default usePageTitle;
