import React from 'react';
import '../JudgementChecker/CheckReviewPerson/SearchOnPerson/SearchOnPerson.css';
import HeaderDropdown from '../../components/HeaderDropdownNative';
import WorkInProgressPng from '../../assets/images/work-in-progress.png';

function UnderConstruction({ title }) {
  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2%',
          }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '30%' }}
          >
            <img src={WorkInProgressPng} alt="Under Construction" />
            <span>
              Select a person and our tool will scan all data sources and
              establish any companies, assets owned, check judgments in multiple
              UK jurisdictions, and check sanction lists, financial and
              insolvency registers.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnderConstruction;
