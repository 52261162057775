import React from 'react';
import './CompanyItem.css';
import { statusDiv } from '../../../../utils/helper';

const CompanyItem = ({ company }) => {
  const isNeedActive = !company.company_status && company.need >= 20;

  const formatOfficers = officers => {
    if (officers?.length === 0) return null;
    if (officers?.length === 1) {
      return officers[0]?.name;
    } else {
      return (
        <>
          {officers[0]?.name}
          &nbsp;
          <a href="#">& {officers?.length - 1} others </a>
        </>
      );
    }
  };

  return (
    <div className="corporate-insights-result-card">
      <div className="corporate-insights-result-card-content">
        <div className="corporate-insights-result-card-content-header">
          <h6>{company?.title}</h6>
        </div>
        <div className="corporate-insights-result-card-body">
          <div className="corporate-insights-result-card-info-row">
            <span className="corporate-insights-result-card-label">Status</span>
            <span
              className={`corporate-insights-result-card-value ${isNeedActive ? 'corporate-insights-result-card-status-active' : 'corporate-insights-result-card-status-inactive'}`}
            >
              {statusDiv(company?.company_status)}
              {isNeedActive && (
                <span className="need-indicator"> (Need 20%)</span>
              )}
            </span>
          </div>
          <div className="corporate-insights-result-card-info-row">
            <span className="corporate-insights-result-card-label">
              Locality:
            </span>
            <span className="corporate-insights-result-card-value">
              {company?.addressObj?.locality}
            </span>
          </div>
          <div className="corporate-insights-result-card-info-row">
            <span className="corporate-insights-result-card-label">
              AddressOne
            </span>
            <span className="corporate-insights-result-card-value">
              {company?.addressObj?.addressOne}
            </span>
          </div>
          <div className="corporate-insights-result-card-info-row">
            <span className="corporate-insights-result-card-label">
              AddressTwo
            </span>
            <span className="corporate-insights-result-card-value">
              {company?.addressObj?.addressTwo}
            </span>
          </div>

          <div className="corporate-insights-result-card-info-row">
            <span className="corporate-insights-result-card-label">
              Date of creation
            </span>
            <span className="corporate-insights-result-card-value">
              {company?.date_of_creation}
            </span>
          </div>

          {company?.pofficers_list && (
            <div className="corporate-insights-result-card-info-row">
              <span className="corporate-insights-result-card-label">
                Officers
              </span>
              <span className="corporate-insights-result-card-value result-link">
                {formatOfficers(company?.pofficers_list)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyItem;
