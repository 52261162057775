import React, { useContext, useEffect, useState } from 'react';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import './CoporateLinksGraph.css';
import CorporateLinksGraphRightSidebar from '../../../components/CorporateLinks/CorporateLinksGraphRightSidebar/CorporateLinksGraphRightSidebar';
import CLGraph from './../../../components/CorporateLinks/CLGraph/CLGraph';
import { AppContext } from '../../../context/Context';
import CorporateLinksGraphLeftSidebar from './../../../components/CorporateLinks/CorporateLinksGraphLeftSidebar/CorporateLinksGraphSidebar';

import {
  getCompanyInfoByCompanyNumber,
  getOfficerInfoByCompanyNumber,
  getPersonInsights,
  getRelationShips,
  getShareholdersOfCompaniesInNetworkGraph,
} from '../../../api/corporateExplorer/corporateExplorer';
import CONSTANTS from '../../../utils/constants';
import { useAuth } from '../../../context/AuthProvider';
import LocalStorageService from '../../../utils/LocalStorageServices';
import { isArray } from 'lodash';
import { getNodeNameById } from '../../../utils/helper';

const CorporateLinksGraph = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const { clGraph, setClGraph, user } = useContext(AppContext);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedOfficer, setSelectedOfficer] = useState(null);
  const [accountInfo, setAccountsInfo] = useState([]);
  const [graphFromState, setGraphFromState] = useState(null);
  const [isInfoLoading, setIsInfoLoading] = useState(false);
  const [isLoadingShareholders, setIsLoadingShareholders] = useState(false);

  const { logout, openLogin, openProfile } = useAuth();
  const [leftSideButtonType, setLeftSideButtonType] = useState(null);
  const [selectedGraph, setSelectedGraph] = useState(null);

  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
  };

  const fetchShareholders = async (sgGraph, graphName) => {
    if (sgGraph) {
      try {
        setIsLoadingShareholders(true);
        let graphWithShareholders =
          await getShareholdersOfCompaniesInNetworkGraph(sgGraph);

        if (Array.isArray(graphWithShareholders?.graph.nodes)) {
          // setSelectedGraph(graphWithShareholders?.graph);
          setClGraph(previousState => ({
            ...previousState,
            shareholders: {
              ...previousState.shareholders,
              [graphName]: graphWithShareholders?.graph,
            },
          }));
        }
        setIsLoadingShareholders(false);
      } catch (error) {
        console.error('Error fetching shareholders:', error);
        setIsLoadingShareholders(false);
      }
    }
  };

  const fetchShareholderReSeprate = async graphs => {
    for (const grf of graphs) {
      let name = getNodeNameById(grf?.nodes, grf?.meta?.selectedEntity?.ids);
      await fetchShareholders(grf, name);
    }
  };

  const resetState = () => {
    setSelectedCompany(null);
    setSelectedOfficer(null);
    setRightSidebarOpen(false);
    setAccountsInfo(null);
  };

  useEffect(() => {
    resetState();
    if (clGraph?.isOpenRightSidebar) {
      const getCompanyInfo = async () => {
        setRightSidebarOpen(clGraph?.isOpenRightSidebar);

        if (!clGraph?.clickedNode) return;

        try {
          if (clGraph?.clickedNode?.entityType === 'company') {
            setIsInfoLoading(true);

            const data = await getCompanyInfoByCompanyNumber(
              clGraph?.clickedNode?.id
            );
            setIsInfoLoading(false);
            setSelectedCompany(data?.companyInfo);
            setAccountsInfo(data?.accountInfo);
          }

          if (clGraph?.clickedNode?.entityType === 'person') {
            setIsInfoLoading(true);

            let preparePayLoad = {
              combinationType: 'officer',
              ids: [
                {
                  type: 'officer',
                  id: clGraph?.clickedNode?.id,
                },
              ],
            };
            preparePayLoad['graphs'] = [clGraph?.selectedGraph];

            const data = await getPersonInsights(preparePayLoad);
            setIsInfoLoading(false);
            setSelectedOfficer(data);
          }
        } catch (error) {
          setIsInfoLoading(false);
        }
      };

      getCompanyInfo();
    }
  }, [clGraph]);

  useEffect(() => {
    if (leftSideButtonType === 'Shareholders') {
      let name = getNodeNameById(
        clGraph?.selectedGraph?.nodes,
        clGraph?.selectedGraph?.meta?.selectedEntity?.ids
      );
      setSelectedGraph(clGraph?.shareholders[name]);
    }

    if (leftSideButtonType === 'Officers & Companies') {
      setSelectedGraph(clGraph?.selectedGraph);
    }
  }, [leftSideButtonType]);

  useEffect(() => {
    let graph = clGraph?.graph;
    setGraphFromState(graph);
    resetState();
  }, []);

  useEffect(() => {
    // Return a cleanup function
    return () => {
      if (user?.guest) {
        const userResponse = window.confirm(
          'Save your results for future reference by logging in or signing up for an account.'
        );

        if (userResponse) {
          LocalStorageService.setItem('searchedGraph', clGraph?.combination);
          openLogin();
        }
      }
    };
  }, []);

  return (
    <div>
      <div className="dashboard-section">
        <div className="main-div">
          <div className="corporate-links-graph-main-content">
            <div>
              <CLGraph
                graph={graphFromState}
                setLeftSideButtonType={setLeftSideButtonType}
                setSelectedGraph={setSelectedGraph}
                selectedGraph={selectedGraph}
                isLoadingShareholders={isLoadingShareholders}
              />
            </div>

            {/* Right sidebar */}
            <div
              className={`corporate-links-graph-right-sidebar ${rightSidebarOpen ? 'open' : ''}`}
            >
              <CorporateLinksGraphRightSidebar
                rightSidebarOpen={rightSidebarOpen}
                toggleRightSidebar={toggleRightSidebar}
                selectedCompany={selectedCompany}
                accountInfo={accountInfo}
                selectedOfficer={selectedOfficer}
                isInfoLoading={isInfoLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateLinksGraph;
