import React, { useContext, useEffect, useState } from 'react';
import './CorporateExploreInsightResults.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../../../context/Context';
import PaginationRT from '../../../components/Pagination/PaginationRT';
import { nationalityToFlag } from '../../../utils/helper';
// import { nationalityToFlag } from '../../../utils/helper';

const CorporateExploreInsightResults = ({ selectedTab, setSelectedTab }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const {
    companiesAndOfficers,
    setOfficer,
    setCompany,
    setFinancialAccountsDoc,
  } = useContext(AppContext);

  // Calculate paginated data
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    if (selectedTab === 'All') {
      setFilteredData([
        ...(companiesAndOfficers?.res?.officer || []),
        ...(companiesAndOfficers?.res?.companies || []),
      ]);
    } else if (selectedTab === 'People') {
      setFilteredData(companiesAndOfficers?.res?.officer || []);
    } else if (selectedTab === 'Companies') {
      setFilteredData(companiesAndOfficers?.res?.companies || []);
    }
    setCurrentPage(0);
  }, [selectedTab, companiesAndOfficers]);

  const formatOfficers = officers => {
    if (officers.length === 0) return null;

    if (officers.length === 1) {
      return officers[0].name; // Only one officer
    } else {
      return (
        <>
          {officers[0].name} {/* Display only the first officer */}
          &nbsp;
          <a href="#">& {officers.length - 1} others</a>
        </>
      );
    }
  };

  const formatCompanies = companies => {
    if (companies.length === 0) return null;

    if (companies.length === 1) {
      return companies[0].company_name;
    } else if (companies.length === 2) {
      return `${companies[0].company_name}, ${companies[1].company_name}`;
    } else {
      return (
        <>
          {`${companies[0].company_name}, ${companies[1].company_name}`}
          &nbsp;
          <a href="#">& {companies.length - 2} others</a>
        </>
      );
    }
  };

  const openCompanyOrOfficer = companyOrOfficer => {
    if (companyOrOfficer?.hasOwnProperty('companies')) {
      setOfficer(companyOrOfficer);
      setCompany(null);
    }

    if (companyOrOfficer?.hasOwnProperty('officers')) {
      setCompany(companyOrOfficer);
      setOfficer(null);
    }
  };

  useEffect(() => {
    setFinancialAccountsDoc([]);
  }, []);

  return (
    <div className="corporate-explore-insight-results-container">
      <h1 className="corporate-explore-insight-results-title">
        Results for {companiesAndOfficers?.query}
      </h1>

      {/* Tabs */}
      <div className="corporate-explore-insight-results-tabs-container">
        <button
          className={`tab ${selectedTab === 'All' ? 'active' : ''}`}
          onClick={() => setSelectedTab('All')}
        >
          All{' '}
          <span className="corporate-explore-insight-results-tab-count">
            {companiesAndOfficers?.res?.officersTotal +
              companiesAndOfficers?.res?.companiesTotal}
          </span>
        </button>
        <button
          className={`tab ${selectedTab === 'People' ? 'active' : ''}`}
          onClick={() => setSelectedTab('People')}
        >
          People{' '}
          <span className="tab-count">
            {companiesAndOfficers?.res?.officersTotal}
          </span>
        </button>
        <button
          className={`tab ${selectedTab === 'Companies' ? 'active' : ''}`}
          onClick={() => setSelectedTab('Companies')}
        >
          Companies{' '}
          <span className="tab-count">
            {companiesAndOfficers?.res?.companiesTotal}
          </span>
        </button>
      </div>

      <div className="corporate-explore-insight-results-cards-container">
        {currentItems?.map((item, index) => (
          <div
            className="corporate-explore-insight-results-main-card"
            key={index}
          >
            <div className="corporate-explore-insight-results-main-card-icon">
              <div className="corporate-explore-insight-results-main-card-icon-placeholder">
                {item.nationality ? (
                  <FontAwesomeIcon
                    icon={faUser}
                    className="corporate-explore-insight-results-main-details-icon"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faBuilding}
                    className="corporate-explore-insight-results-main-details-icon"
                  />
                )}
              </div>
            </div>

            <div className="corporate-explore-insight-results-main-card-content">
              <h2 onClick={() => openCompanyOrOfficer(item)}>{item?.name}</h2>
              {item.status && (
                <p>
                  <span className="corporate-explore-insight-results-main-card-status">
                    Status:
                  </span>{' '}
                  <span className={`status ${item.status.toLowerCase()}`}>
                    {item?.status}
                  </span>
                </p>
              )}

              {item?.officers && item.officers.length > 0 && (
                <p>
                  <span className="corporate-explore-insight-results-main-card-status">
                    Officers:
                  </span>{' '}
                  <span className="corporate-explore-insight-results-main-card-status-officers">
                    {formatOfficers(item?.officers)}
                  </span>
                </p>
              )}

              {item.role && (
                <p>
                  <span className="corporate-explore-insight-results-main-card-status">
                    Role:
                  </span>{' '}
                  {item?.role}
                </p>
              )}
              {item.nationality && (
                <p>
                  <span className="corporate-explore-insight-results">
                    Nationality:{item?.nationality}
                  </span>{' '}
                  {(() => {
                    const formattedNationality = item?.nationality
                      ?.toUpperCase()
                      .replace(' ', '');
                    return nationalityToFlag[formattedNationality] ? (
                      <img
                        src={nationalityToFlag[formattedNationality]}
                        alt={`${item.nationality} flag`}
                        style={{
                          width: '15px',
                          height: '10px',
                        }}
                      />
                    ) : null;
                  })()}
                </p>
              )}

              {item?.companies && (
                <p>
                  <span className="corporate-explore-insight-results-main-card-status">
                    Companies:
                  </span>{' '}
                  <span className="corporate-explore-insight-results-main-card-status-companies">
                    {formatCompanies(item?.companies)}
                  </span>
                </p>
              )}

              {item.assets && (
                <p>
                  <span className="corporate-explore-insight-results-main-card-status">
                    Assets:
                  </span>{' '}
                  {item.assets}
                </p>
              )}

              {item.liabilities && (
                <p>
                  <span className="corporate-explore-insight-results-main-card-status">
                    Liabilities:
                  </span>{' '}
                  {item.liabilities}
                </p>
              )}

              {item.net_assets && (
                <p>
                  <span className="corporate-explore-insight-results-main-card-status">
                    Net Assets:
                  </span>{' '}
                  {item.net_assets}
                </p>
              )}

              {item.address && (
                <p>
                  <span className="corporate-explore-insight-results-main-card-status">
                    Address:
                  </span>{' '}
                  {item.address}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>

      <div style={{ marginTop: '5px' }}>
        <PaginationRT
          totalPages={Math.ceil(filteredData?.length / itemsPerPage)}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default CorporateExploreInsightResults;
