import React, { useEffect } from 'react';
import './CEIPersonInsightsDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBriefcase,
  faGlobe,
  faUser,
  faCalendar,
  faSuitcase,
} from '@fortawesome/free-solid-svg-icons';
import { nationalityToFlag } from '../../../../utils/helper';

const CEIPersonInsightsDetails = ({ details }) => {
  useEffect(() => {
    if (details?.nationality) {
      const nationality = details?.nationality?.toUpperCase().replace(' ', '');
      const flag = nationalityToFlag[nationality];
    
    }
  }, [details]);

  return (
    <div className="corporate-explore-details-container">
      <h3 className="corporate-explore-details-title">Details</h3>
      <div className="corporate-explore-details-item">
        <FontAwesomeIcon
          icon={faBriefcase}
          className="corporate-explore-details-icon"
        />
        <span className="corporate-explore-details-label">Role</span>
        <span className="corporate-explore-details-value">{details?.role}</span>
      </div>
      <div className="corporate-explore-details-item">
        <FontAwesomeIcon
          icon={faGlobe}
          className="corporate-explore-details-icon"
        />
        <span className="corporate-explore-details-label">
          Country Of Residence
        </span>
        <span className="corporate-explore-details-value">
          {details?.country_of_residence}
        </span>
      </div>
      <div className="corporate-explore-details-item">
        <FontAwesomeIcon
          icon={faUser}
          className="corporate-explore-details-icon"
        />
        <span className="corporate-explore-details-label">Nationality</span>
        <span className="corporate-explore-details-value">
          {'   '}
          {nationalityToFlag[
            details?.nationality
              ?.toUpperCase()
              .replace(' ', '')
              .replace('.', '')
          ] && (
            <img
              src={
                nationalityToFlag[
                  details?.nationality
                    ?.toUpperCase()
                    .replace(' ', '')
                    .replace('.', '')
                ]
              }
              alt={`${details?.nationality} flag`}
              style={{
                width: '15px',
                height: '10px',
              }}
            />
          )}
          {details?.nationality && (
            <span style={{ marginLeft: '2px' }}>
              {details?.nationality?.toUpperCase().replace(' ', '')}
            </span>
          )}
        </span>
      </div>
      <div className="corporate-explore-details-item">
        <FontAwesomeIcon
          icon={faCalendar}
          className="corporate-explore-details-icon"
        />
        <span className="corporate-explore-details-label">Date of Birth</span>
        <span className="corporate-explore-details-value">
          {details?.date_of_birth}
        </span>
      </div>
      <div className="corporate-explore-details-item">
        <FontAwesomeIcon
          icon={faSuitcase}
          className="corporate-explore-details-icon"
        />
        <span className="corporate-explore-details-label">Occupation</span>
        <span className="corporate-explore-details-value">
          {details?.occupation}
        </span>
      </div>
    </div>
  );
};

export default CEIPersonInsightsDetails;
