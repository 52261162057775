import './SearchSuggestions.css';
import { useState, useCallback, useEffect } from 'react';
import {
  debounce,
  processRegisteredOfficeAddress,
  processRegisteredOfficeAddressObject,
} from '../../utils/helper';
import {
  explorerFilter,
  getCompaniesOrOfficersByName,
} from '../../api/corporateExplorer/corporateExplorer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../../context/Context';
import { useContext } from 'react';
import { useLoading } from '../../context/LoadingContext/LoadingContext';
import TableLoadingScreen from '../LoadingScreen/TableLoadingScreen/TableLoadingScreen';
const SearchSuggestions = ({
  setSelectedCompany,
  setAccountsInfo,
  setSelectedOfficer,
  setSelectedTab,
  handleNavigation,
}) => {
  const { setCompaniesAndOfficers, setFinancialAccountsProcess } =
    useContext(AppContext);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [totalCompanies, setTotalCompanies] = useState(null);
  const [totalOfficers, setTotalOfficers] = useState(null);

  const { isLoading, setIsLoading } = useLoading();
  const [isLoadingSuggestion, setIsLoadingSuggestion] = useState(false);

  // Wrap the debounced function in useCallback to prevent it from being re-created on every render
  const debouncedSearchCompanyByName = useCallback(
    debounce(async companyHouseSearchName => {
      if (!companyHouseSearchName) return setSuggestions([]);
      // setIsLoading(true);

      try {
        setIsLoadingSuggestion(true);
        const entitiesWithOptions = await explorerFilter([
          { header: 'company_name', filter: ['+' + companyHouseSearchName] },
        ]);

        let accountsinfo = entitiesWithOptions?.accountsInfo;

        let mapResults = entitiesWithOptions?.result
          ?.map(company => ({
            id: company?._id,
            title: company?._source?.company_name,
            address: processRegisteredOfficeAddress(company?._source),
            addressObj: processRegisteredOfficeAddressObject(company?._source),
            date_of_creation: company?._source?.date_of_creation,
            company_status: company?._source?.company_status,
            company_number: company?._source?.company_number,
            confirmation_statement: company?._source?.confirmation_statement,
            accounts: company?._source?.accounts,
            sic_codes: company?._source?.sic_codes,
            type: company?._source?.type,
            foreign_company_details: company?._source?.foreign_company_details,
            external_registration_number:
              company?._source?.external_registration_number,
          }))
          .slice(0, 5);
        setAccountsInfo(accountsinfo);
        setSuggestions(mapResults || []);
        setTotalCompanies(entitiesWithOptions?.pagination?.total);

        setTotalOfficers(entitiesWithOptions?.pagination?.totalOfficers);
        setSelectedOfficer(null);
        setIsLoadingSuggestion(false);
      } catch (error) {
        console.error('Failed to fetch company suggestions:', error);
        setSelectedOfficer(null);
        setIsLoadingSuggestion(false);
      } finally {
        // setIsLoading(false);
      }
    }, 600),
    [] // Only create this function once
  );

  const searchCompaniesAndOfficers = async (inputValue, searchType) => {
    if (inputValue && inputValue.length > 0) {
      try {
        setSelectedTab(searchType);
        setIsLoading(true);
        if (inputValue && inputValue?.length === 0) return;
        const companiesAndOfficers =
          await getCompaniesOrOfficersByName(inputValue);

        setCompaniesAndOfficers({
          res: companiesAndOfficers,
          query: inputValue,
        });

        setIsLoading(false);
        setSuggestions([]);
        setSelectedOfficer(null);
        setSelectedCompany(null);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handleChange = event => {
    const { value } = event.target;
    setInputValue(value);

    debouncedSearchCompanyByName(value);
  };

  const handleSuggestionClick = suggestion => {
    setFinancialAccountsProcess({
      graphData: { isLoading: false, data: null },
      nonGraphData: { isLoading: false, data: null },
    });
    setInputValue(suggestion.title);
    setSelectedCompany(suggestion);
    setSuggestions([]);
  };
  useEffect(() => {
    const fetchData = async () => {
      if (handleNavigation && handleNavigation?.searchInsight) {
        await searchCompaniesAndOfficers(
          handleNavigation?.searchInsight,
          'All'
        );
      }
    };

    fetchData();
  }, [handleNavigation]);

  return (
    <div style={{ width: '50%' }}>
      <div style={{ display: 'flex', height: '40px' }}>
        <input
          style={{
            borderTopLeftRadius: '20px',
            borderBottomLeftRadius: '20px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            fontSize: '15px',
          }}
          className="form-control"
          type="search"
          placeholder="Search"
          value={inputValue}
          onChange={handleChange}
        />
        <div
          style={{
            backgroundColor: '#ffdd8e',
            borderTopRightRadius: '20px',
            borderBottomRightRadius: '20px',
            width: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => searchCompaniesAndOfficers(inputValue, 'All')}
        >
          {isLoadingSuggestion ? (
            <div>
              <TableLoadingScreen />
            </div>
          ) : (
            <FontAwesomeIcon icon={faSearch} />
          )}
        </div>
      </div>
      {/* {isLoading && <div>Loading...</div>} */}
      {suggestions?.length > 0 && (
        <ul className="suggestions-list">
          {totalCompanies && totalCompanies > 0 ? (
            <li
              className="suggestions-list-bold"
              onClick={() =>
                searchCompaniesAndOfficers(inputValue, 'Companies')
              }
            >
              View{' '}
              <span className="search-suggestions-companies">
                {totalCompanies}
              </span>{' '}
              Companies
            </li>
          ) : null}
          {totalOfficers && totalOfficers > 0 ? (
            <li
              className="suggestions-list-bold"
              onClick={() => searchCompaniesAndOfficers(inputValue, 'People')}
            >
              View{' '}
              <span className="search-suggestions-directors">
                {totalOfficers}
              </span>{' '}
              Directors
            </li>
          ) : null}
          {suggestions.map(item => (
            <>
              <li key={item.id} onClick={() => handleSuggestionClick(item)}>
                {item.title}
              </li>
            </>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchSuggestions;
