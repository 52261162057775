import React from 'react';
import overview from '../../../assets/images/company1.png';
import './CorporateExploreInsightLogo.css';

const CorporateExploreInsightLogo = ({ selectedCompany }) => {
  return (
    <div className="corporate-explore-insight-container-logo">
      <div className="corporate-explore-insight-company-Logo">
        <img src={overview} alt="Company Logo" className="company-logo" />
        <div className="corporate-explore-insight-company-Name">
          <a href="#" style={{ color: '#021B41' }}>
            {selectedCompany?.title}
          </a>
        </div>
        <div className="corporate-explore-insight-company-Address">
          {selectedCompany?.address}
        </div>{' '}
      </div>
    </div>
  );
};

export default CorporateExploreInsightLogo;
