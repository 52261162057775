import React, { useContext, useEffect } from 'react';
import LineHeader from '../../../assets/images/icons/header-line.svg';
import detailIcon from '../../../assets/images/icons/bx_detail.svg';
import referenceIcon from '../../../assets/images/icons/material-symbols_quick-reference-outline.svg';
import IDIcon from '../../../assets/images/icons/ant-design_number-outlined.svg';
import LinkIcon from '../../../assets/images/icons/ph_link.svg';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import Pagination from '../../../components/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import '../../../components/CustomerTable.css';
import '../CESearchEntities/CESearchEntities.css';
import { getNetworkGraphByUserId } from '../../../api/corporateExplorer/corporateExplorer';
import { useState } from 'react';
import { AppContext } from '../../../context/Context';
import {
  convertTimestampToDateTime,
  getEntityNamesFromObjectGraph,
} from '../../../utils/helper';

const CLHistoryReports = ({ showHeader = true, removePadding = false }) => {
  const navigate = useNavigate();

  const [history, setHistory] = useState([]);
  const { user, setClGraph } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const viewOnCorporateLinksGraph = graphs => {
    setClGraph(previousState => ({
      ...previousState,
      graph: graphs?.graphs?.result?.graphs,
      combination: graphs?.combination,
    }));
    navigate('/cl-dashboard/cl-graph');
  };

  useEffect(() => {
    if (user) {
      const getGraphHistory = async () => {
        const graphHistoryResponse = await getNetworkGraphByUserId(
          user?.FirebaseUser?.user_id,
          currentPage + 1,
          5
        );
        setHistory(graphHistoryResponse?.data);
        setTotalPages(graphHistoryResponse?.totalPages);
      };

      getGraphHistory();
    }
  }, [user, currentPage]);

  return (
    <div style={{ width: '100%' }}>
      <div className="dashboard-section">
        <div className="main-div">
          {/* {showHeader && (
            <div className="header">
              <div className="header-heading dark-heading-color">
                Corporate Links History
              </div>
              <HeaderDropdown />
            </div>
          )} */}
          <div
            className="dashboard-content-div"
            style={
              removePadding
                ? { paddingLeft: '0px', paddingRight: '0px' }
                : undefined
            }
          >
            <div className="cesearch-entites-dashboard-content">
              <div className="customerTable-info-section">
                <div className="header-section">
                  <div className="body-text-normal dark-text-color">
                    <img src={LineHeader} alt="icon here" /> Corporate Links
                    History
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-custom">
                    <thead>
                      <tr>
                        <td scope="col">
                          <div className="thead-td-custom">
                            <img
                              className="img-thead"
                              src={detailIcon}
                              alt="icon here"
                            />
                            Generated on
                          </div>
                        </td>
                        <td scope="col">
                          <div className="thead-td-custom">
                            <img
                              className="img-thead"
                              src={detailIcon}
                              alt="icon here"
                            />
                            Combination type
                          </div>
                        </td>
                        <td scope="col">
                          <div className="thead-td-custom">
                            <img
                              className="img-thead"
                              src={referenceIcon}
                              alt="icon here"
                            />
                            Combination
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(history) &&
                        history.map(item => (
                          <tr>
                            <td>
                              {convertTimestampToDateTime(item?.timestamp)}
                            </td>
                            <td className="body-text-bold">
                              {item?.combination?.combinationType}
                            </td>
                            <td>
                              <span>{getEntityNamesFromObjectGraph(item)}</span>
                            </td>
                            <td>
                              {/* <span
                                className="body-text-bold"
                                style={{
                                  color: '#0466D1',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  marginRight: '5px', // Adds space between the two links
                                }}
                              >
                                Download
                              </span> */}
                              <span
                                className="body-text-bold"
                                style={{
                                  color: '#0466D1',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => viewOnCorporateLinksGraph(item)}
                              >
                                View Graph
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div style={{ marginTop: '25px' }}></div>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CLHistoryReports;
