import React, { useContext, useEffect, useState } from 'react';
import './CEIFinancials.css';
import {
  calculateDebtRatio,
  calculateNetAssets,
  forMateDateIntoEnglish,
  formatNumber,
} from '../../../utils/helper';
import AreaChart from '../../AreaChart/AreaChart';
import { AppContext } from '../../../context/Context';
import TableLoadingScreen from '../../LoadingScreen/TableLoadingScreen/TableLoadingScreen';
import { getUseableDocumentUrl } from '../../../api/corporateExplorer/corporateExplorer';
import {
  financialDocumentParser,
  multiFinancialDocumentParser,
} from '../../../api/financialAccounts/financialAccounts';
const CEIFinancials = ({ selectedCompany, accountInfo }) => {
  const { financialAccountsProcess, setFinancialAccountsProcess } =
    useContext(AppContext);
  const [accountFinalObjHolder, setAccountFinalObjHolder] = useState({});
  const [toDocumentDate, setTopDocumentDate] = useState('');
  const [graphData, setGraphData] = useState({
    labels: ['1', '1'],
    datasets: [
      {
        label: 'Net Assets',
        data: ['1', '1'],
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        fill: true,
      },
      {
        label: 'Total Assets',
        data: ['1', '1'],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        fill: true,
      },
      {
        label: 'Total Liabilities',
        data: ['1', '1'],
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
        fill: true,
      },
    ],
  });

  const setNonGraphDataLoading = isLoading => {
    setFinancialAccountsProcess(prevState => ({
      ...prevState,
      nonGraphData: {
        ...prevState.nonGraphData,
        isLoading: isLoading, // Update only isLoading
      },
    }));
  };

  const setGraphDataLoading = isLoading => {
    setFinancialAccountsProcess(prevState => ({
      ...prevState,
      graphData: {
        ...prevState.graphData,
        isLoading: isLoading, // Update only isLoading in graphData
      },
    }));
  };

  const updateNonGraphData = (financialData, date) => {
    setFinancialAccountsProcess(prevState => ({
      ...prevState,
      nonGraphData: {
        ...prevState.nonGraphData,
        data: financialData,
        isLoading: false,
        date: date,
      },
    }));
  };

  const updateGraphData = financialData => {
    setFinancialAccountsProcess(prevState => ({
      ...prevState,
      graphData: {
        ...prevState.graphData,
        data: financialData,
        isLoading: false, // Optionally, update isLoading if needed
      },
    }));
  };

  const [showLoadingForFinancalGraph, setShowLoadingForFinancalGraph] =
    useState(false);

  const [showLoadingForFinancalPoints, setShowLoadingForFinancalPoints] =
    useState(false);

  const transformDataToCustomFormat = data => {
    if (Array.isArray(data) && data.length > 0) {
      // Filter out entries where parsedData is null
      const filteredData = data.filter(item => item?.parsedData !== null);

      // Extract years from the filtered data array and sort them in ascending order
      const labels = filteredData.map(item => item?.year).sort((a, b) => a - b);

      // Initialize arrays for Net Assets, Total Assets, and Total Liabilities
      let netAssets = Array(labels.length).fill(null);
      let totalAssets = Array(labels.length).fill(null);
      let totalLiabilities = Array(labels.length).fill(null);

      // Loop through the filtered data to populate the arrays
      filteredData.forEach(({ parsedData, year }) => {
        // Find the index of the year in the sorted labels array
        const yearIndex = labels.indexOf(year);

        if (yearIndex !== -1) {
          netAssets[yearIndex] = parsedData?.equity;
          totalAssets[yearIndex] = parsedData?.total_assets;
          totalLiabilities[yearIndex] =
            Math.sign(parsedData?.total_liabilities) === -1
              ? parsedData?.total_liabilities
              : -parsedData?.total_liabilities;
        }
      });

      // Return the custom data in the required format
      return {
        labels,
        datasets: [
          {
            label: 'Net Assets',
            data: netAssets,
            borderColor: 'rgba(54, 162, 235, 1)',
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            fill: true,
          },
          {
            label: 'Total Assets',
            data: totalAssets,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
            fill: true,
          },
          {
            label: 'Total Liabilities',
            data: totalLiabilities,
            borderColor: 'rgba(153, 102, 255, 1)',
            backgroundColor: 'rgba(153, 102, 255, 0.5)',
            fill: true,
          },
        ],
      };
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      if (financialAccountsProcess?.nonGraphData?.data !== null) {
        setAccountFinalObjHolder(financialAccountsProcess?.nonGraphData?.data);
      } else {
        if (!financialAccountsProcess?.nonGraphData?.isLoading) {
          const fetchFinancialRecentInformation = async () => {
            // single data only to show text value of accounts
            try {
              setShowLoadingForFinancalPoints(true);
              setNonGraphDataLoading(true);
              let financialData = await financialDocumentParser({
                company_number: selectedCompany?.company_number,
              });
              setTopDocumentDate(financialData?.data[0]?.date_complete);
              let parsedData = {
                total_liabilities:
                  financialData?.data[0]?.parsedData?.total_liabilities,
                total_assets: financialData?.data[0]?.parsedData?.total_assets,
                cash_in_bank: financialData?.data[0]?.parsedData?.cash_in_bank,
              };

              setAccountFinalObjHolder(parsedData);
              updateNonGraphData(
                parsedData,
                financialData?.data[0]?.date_complete
              );
              setShowLoadingForFinancalPoints(false);
            } catch (error) {
              setShowLoadingForFinancalPoints(false);
              setNonGraphDataLoading(false);
            }
          };
          fetchFinancialRecentInformation();
        }
      }

      if (financialAccountsProcess?.graphData?.data !== null) {
        setGraphData(financialAccountsProcess?.graphData?.data);
      } else {
        if (!financialAccountsProcess?.graphData?.isLoading) {
          const fetchFinancialAllYearsInformation = async () => {
            // for graph data
            try {
              setShowLoadingForFinancalGraph(true);
              setGraphDataLoading(true);
              let multiFinancialData = await multiFinancialDocumentParser({
                company_number: selectedCompany?.company_number,
              });

              let dataForGraph = transformDataToCustomFormat(
                multiFinancialData?.data
              );

              setGraphData(dataForGraph);
              updateGraphData(dataForGraph);
              setShowLoadingForFinancalGraph(false);
            } catch (error) {
              setShowLoadingForFinancalGraph(false);
              setGraphDataLoading(false);
            }
          };

          fetchFinancialAllYearsInformation();
        }
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    setShowLoadingForFinancalPoints(
      financialAccountsProcess?.nonGraphData?.isLoading
    );
    setShowLoadingForFinancalGraph(
      financialAccountsProcess?.graphData?.isLoading
    );

    if (!financialAccountsProcess?.graphData?.isLoading) {
      setGraphData(financialAccountsProcess?.graphData?.data);
    }

    if (!financialAccountsProcess?.nonGraphData?.isLoading) {
      setAccountFinalObjHolder(financialAccountsProcess?.nonGraphData?.data);
      setTopDocumentDate(financialAccountsProcess?.nonGraphData?.date);
    }
  }, [financialAccountsProcess]);

  return (
    <>
      <div className="financials-container">
        <h4 className="financials-title">Financials</h4>
        <div style={{ marginBottom: '20px' }} className="financials-area-chart">
          <div style={{ position: 'relative' }}>
            <AreaChart data={graphData} />

            {showLoadingForFinancalGraph && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  zIndex: 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <TableLoadingScreen width="80px" />
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <div style={{ position: 'relative' }}>
            <>
              <div className="financials-content">
                <div className="financial-item">
                  <span className="label">Year Ended</span>
                  <p className="value">
                    {showLoadingForFinancalPoints !== true
                      ? forMateDateIntoEnglish(toDocumentDate)
                      : 'Pending'}
                  </p>
                </div>
                <div className="financial-item">
                  <span className="label">Total Assets</span>
                  <p className="value text-info">
                    {showLoadingForFinancalPoints !== true
                      ? ` £${formatNumber(accountFinalObjHolder?.total_assets)}`
                      : 'Pending'}
                  </p>
                </div>
                <div className="financial-item">
                  <span className="label">Total Liabilities</span>
                  <p className="value text-primary">
                    {showLoadingForFinancalPoints !== true
                      ? `£${formatNumber(-accountFinalObjHolder?.total_liabilities)}`
                      : 'Pending'}
                  </p>
                </div>
                <div className="financial-item">
                  <span className="label">Net Assets</span>
                  <p className="value text-primary">
                    {showLoadingForFinancalPoints !== true
                      ? `£${calculateNetAssets(
                          accountFinalObjHolder?.total_assets,
                          accountFinalObjHolder?.total_liabilities
                        )}`
                      : 'Pending'}
                  </p>
                </div>
                <div className="financial-item">
                  <span className="label">Cash in Bank</span>
                  <p className="value">
                    {showLoadingForFinancalPoints !== true
                      ? accountFinalObjHolder?.cash_in_bank
                        ? `£${formatNumber(accountFinalObjHolder?.cash_in_bank)}`
                        : 'Unreported'
                      : 'Pending'}
                  </p>
                </div>

                <div className="financial-item">
                  <span className="label">Employees</span>
                  <p className="value">Unreported</p>
                </div>
                <div className="financial-item">
                  <span className="label">Turnover</span>
                  <p className="value">Unreported</p>
                </div>
                <div className="financial-item">
                  <span className="label">Debt Ratio (%)</span>
                  <p className="value">
                    {showLoadingForFinancalPoints !== true
                      ? calculateDebtRatio(
                          accountFinalObjHolder?.total_assets,
                          accountFinalObjHolder?.total_liabilities
                        )
                      : 'Pending'}
                  </p>
                </div>
              </div>
              <a href="#" className="view-financials-link">
                VIEW FINANCIALS
              </a>
            </>

            {showLoadingForFinancalPoints && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  zIndex: 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'bottom',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <TableLoadingScreen width="80px" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CEIFinancials;
