import React, { useState } from 'react';
import Slider from 'react-slider'; // Assuming you're using react-slider
import './CESFilterSlider.css';

const DateRangeSlider = ({
  labelName,
  values,
  setValues,
  minDate,
  maxDate,
}) => {
  const handleChange = newValues => {
    setValues(newValues);
  };

  return (
    <div className="main-slider">
      <span className="company-filter-date-range">{labelName}</span>
      <Slider
        className="slider"
        value={values}
        onChange={handleChange}
        min={minDate}
        max={maxDate}
        step={1}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <div style={{ marginRight: '20px' }}>
          <label htmlFor="minYear">Min Year: {values[0]}</label>
        </div>
        {'   '}
        <div>
          <label htmlFor="maxYear">Max Year : {values[1]}</label>
        </div>
      </div>
    </div>
  );
};

export default DateRangeSlider;
