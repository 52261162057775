import axios from 'axios';
import { apiConfig } from '../apiConfig';

export const financialDocumentParser = async payload => {
  try {
    const response = await axios.post(
      apiConfig.financialAccounts.endpoints.financial_document_parser,
      payload
    );

    return {
      success: true,
      data: response?.data,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const multiFinancialDocumentParser = async payload => {
  try {
    const response = await axios.post(
      apiConfig.financialAccounts.endpoints.multi_financial_document_parser,
      payload
    );

    return {
      success: true,
      data: response?.data,
    };
  } catch (error) {
    return { success: false, error: error.message };
  }
};
