import { Routes, Route } from 'react-router-dom';
import HeaderDropdown from './components/HeaderDropdownNative';
import Sidebar from './components/Sidebar';
import { routesConfig } from './routeConfig.js';
import usePageTitle from './context/PageContext/PageContext.js';
import ToolTips from './components/ToolTips/ToolTips.js';

const MainLayout = () => {
  const pageTitle = usePageTitle();

  const renderRoutes = routes => {
    return routes.map((route, index) => {
      if (route.children) {
        return (
          <Route key={index} path={route.path} element={route.element}>
            {renderRoutes(route.children)}
          </Route>
        );
      }
      return <Route key={index} path={route.path} element={route.element} />;
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingTop: '5px',
          paddingBottom: '5px',
          alignItems: 'center',
          position: 'sticky',
          top: '0',
          zIndex: '1000',
          backgroundColor: '#fff',
          boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
        }}
      >
        <div
          style={{
            fontSize: '15px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {pageTitle}
        </div>
        <HeaderDropdown />
      </div>
      <Sidebar />
      <Routes>{renderRoutes(routesConfig)}</Routes>
      <ToolTips />
    </div>
  );
};

export default MainLayout;
