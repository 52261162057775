export let routeConfig = {
  'ccj-dashboard/judgement-checker': [
    '/ccj-dashboard/judgement-checker-person',
    '/ccj-dashboard/judgement-checker-business',
    '/ccj-dashboard/check-review-business',
    '/ccj-dashboard/check-review-person',
    '/ccj-dashboard/judgement-checker-your-detail',
    '/ccj-dashboard/report',
  ],
  '/cl-dashboard/search-entites': ['/cl-dashboard/cl-graph'],
  '/ce-dashboard/ce-corporate-explorer': [
    '/ce-dashboard/ce-corporate-explorer',
  ],
  '/fo-dashboard/foregn-uk-ownership': ['/fo-dashboard/foregn-uk-ownership'],
  '/bi-dashboard/background-investigation': [
    '/bi-dashboard/background-investigation',
  ],
  '/fbf-dashboard/feedbackform': ['/fbf-dashboard/feedbackform'],
  '/cei-dashboard/cei-corporate-explore-insight': [
    '/cei-dashboard/cei-corporate-explore-insight',
  ],
};
