import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import showToast from '../../utils/showToast';

function PaymentCancel() {
  const navigate = useNavigate();
  useEffect(() => {
    showToast('Payment Cancel');
    navigate('/ccj-dashboard/judgement-checker');
  }, []);
  return <div>PaymentCancel</div>;
}

export default PaymentCancel;
