import React from 'react';
import HeaderDropdown from '../../../../components/HeaderDropdownNative';
import '../../JudgementChecker.css';
import './PrivacyPolicy.css';
import '../TermsAndPolicy.css';

function PrivacyPolicy() {
  return (
    <div
      className="dashboard-section dashboard-content-div-overflow"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div className="main-div"></div>
      <div className="judgement_check_privacy_policy">
        <div className="judgement_check_privacy_policy_consumer_complaints">
          <span className="judgement_check_privacy_policy_consumer_complaints_heading">
            Consumer Complaints Policy & Procedure{' '}
          </span>
          <div>Our aim is to ensure that: </div>
        </div>
        <ol>
          <li>
            Anyone who is dissatisfied with the service they have received is
            able to make a complaint in a simple, uncomplicated way{' '}
          </li>
          <li>Complaints are investigated fully and promptly</li>
          <li>
            Customers receive a full and thorough explanation of any findings
          </li>
          <li>
            Complaints are monitored and used to review and improve the service
            provided by Registry Trust
          </li>
        </ol>
        <div className="judgement_check_privacy_policy_make_complaint">
          <div className="judgement_check_privacy_policy_consumer_complaints_heading">
            How to make a complaint{' '}
          </div>
        </div>
        <span>
          To enable us to understand your complaint fully there are specific
          pieces of information we require. You may find it useful to obtain and
          complete a Probe Digital{' '}
          <a
            href={process.env.REACT_APP_COMPLAINT_PDF}
            target="_blank"
            rel="noreferrer"
          >
             complaint form.
          </a>
        </span>
        <span>
          If you have specific needs which affect your ability to complete a
          form, we can provide assistance over the telephone (phone us on{' '}
          <span className="judgement_check_privacy_policy_phone">
            020 8144 2812
          </span>
          ). The form can be completed online or printed and completed by hand.
        </span>
        <div className="judgement_check_privacy_policy_contact_us">
          <span>You can submit your completed complain form by email to: </span>
          <span>
            <a href="mailto:contact@probedigital.co.uk">
              contact@probedigital.co.uk
            </a>
          </span>
          <span>
            including{' '}
            <span className="judgement_check_privacy_policy_operations_manger">
              Operations Manager
            </span>{' '}
            in the subject heading
          </span>
        </div>
        <div className="judgement_check_privacy_policy_make_complaint">
          <div className="judgement_check_privacy_policy_consumer_complaints_heading">
            What happens next?
          </div>
        </div>
        <span>
          Your complaint will be reviewed by one of our Operations Managers and
          you will receive an acknowledgement within two working days. We always
          look to address complaints quickly and efficiently, and will look to
          answer your complaint in full within five working days. In the event
          of any delay we will let you know.
        </span>
        <div className="judgement_check_privacy_policy_make_complaint">
          <div className="judgement_check_privacy_policy_consumer_complaints_heading">
            Right to appeal
          </div>
        </div>
        <span>
          If you are not satisfied with the response that you receive, you may
          appeal the decision in writing within 28 working days. Details of how
          to appeal will be found in your initial response letter.
        </span>
        <span>
          An acknowledgement of your appeal will be sent within five working
          days of receipt. A full and final response will then be sent from a
          senior manager within 14 working days.
        </span>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
