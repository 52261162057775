import React, { useEffect } from 'react';
import './CEIPersonInsightsBio.css'; // Import the CSS file
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
const CEIPersonInsightsBio = ({ details }) => {
  const ccjReport = detail => {
    if (detail?.details) {
      const baseUrl = window.location.origin;
      const address = encodeURIComponent(
        JSON.stringify(detail?.details?.address)
      );
      const intro = encodeURIComponent(JSON.stringify(detail?.intro));
      const newPath = '/ccj-dashboard/judgement-checker-person';
      const newUrl = `${baseUrl}${newPath}?intro=${intro}&address=${address}`;
      window.open(newUrl, '_blank');
    }
  };

  return (
    <div className="corporate-explore-person-insights-bio">
      <div>
        <div className="corporate-explore-insights-person-icon ">
          <FontAwesomeIcon icon={faUser} />
        </div>
      </div>

      <h2 className="corporate-explore-insights-person-name">
        {/* {details?.intro?.name} */}
      </h2>
      <p className="corporate-explore-insights-person-details">
        {details?.intro?.bio}
      </p>
      <button
        className="corporate-explore-ccj-report-btn"
        onClick={() => ccjReport(details)}
        style={{ marginLeft: '0px' }}
      >
        <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '5px' }} />
        CCJ Report
      </button>
    </div>
  );
};

export default CEIPersonInsightsBio;
