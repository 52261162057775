import './Home.css';
import React, { useEffect, useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import Header from '../../components/Header';
import Banner from '../../components/Banner';
// import Footer from '../../components/Footer';
// import CopywriteLiine from '../../components/CopywriteLiine';
import Individuals from '../../assets/images/pages/Home/individuals.png';
import Investors from '../../assets/images/pages/Home/investors.png';
import LegalFirms from '../../assets/images/pages/Home/legal-firms.png';
import Businesses from '../../assets/images/pages/Home/businesses.png';
import WhoAreWe from '../../assets/images/pages/Home/who-are-we.png';
import OurServices from '../../assets/images/pages/Home/our-services.png';
import OurApproach from '../../assets/images/pages/Home/our-approach.png';
import OurGoal from '../../assets/images/pages/Home/our-goal.png';
import GlobeProperty from '../../assets/images/icons/Globe_Property_Icon.png';
import SearchIcon from '../../assets/images/what/company.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../context/Context';
import CONSTANTS from '../../utils/constants';
import { useAuth } from '../../context/AuthProvider';
import PostAuthFlow from './PostAuthFlow/PostAuthFlow';
import Footer from './../../components/Footer';
import CopywriteLiine from './../../components/CopywriteLiine';
import StartJourney from './StartJourney/StartJourney';

function Home() {
  const navigate = useNavigate();
  const { openSignup } = useAuth();
  const { user, setUser } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [testHomeComponent, setTestHomeComponent] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setSearchParams({});
  };

  useEffect(() => {
    const probeToken = searchParams.get('probe_token');
    if (probeToken === '01eb26ec-9fbb-11ee-8c90-0242ac120002') {
      setShowModal(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV_NAME === 'prod') {
      setTestHomeComponent(false);
      window.location.href = CONSTANTS.HUB_SPOT_URL;
    } else {
      setTestHomeComponent(true);
    }
  }, []);

  return (
    <>
      {testHomeComponent && (
        <div>
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Check Your Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Please check your email for further instructions.
            </Modal.Body>
          </Modal>
          <Header />
          <div>
            <PostAuthFlow
              headerMessage={'Successfully Logged In'}
              subMessage={
                "As you have successfully logged in now, let's explore the array of solutions we have in store for you below."
              }
            />
            <StartJourney/>
            <Footer />
            <CopywriteLiine />
          </div>
          {/* <div>
            <Banner />
            <section className="who-we-help-section" id="aboutUs">
              <p className="first-heading section-heading text-center dark-heading-color">
                Who We Help
              </p>
              <p className="body-text text-center dark-text-color">
                We attract clients who value our quality of service,
                professionalism, creativity and expertise. Our clientele
                includes national and international law firms, corporations,
                financial institutions and private individuals.
              </p>

              <div className="cards-outer-div">
                <div className="cards-div">
                  <img alt="icon" src={Individuals} />
                  <p className="heading-2">Professionals</p>
                  <p className="body-text">
                    We assist Forensics Accountants, Corporate and Private
                    Investigators, to efficiently carry out background research
                    on individuals and companies
                  </p>
                </div>
                <div className="cards-div">
                  <img alt="icon" src={Investors} />
                  <p className="heading-2">Investors</p>
                  <p className="body-text">
                    Mitigate risk, substantiate credibility, and assess
                    financial stability, thereby ensuring robust, informed
                    decision-making.
                  </p>
                </div>
                <div className="cards-div">
                  <img alt="icon" src={LegalFirms} />
                  <p className="heading-2">Legal Firms</p>
                  <p className="body-text">
                    Verify credibility, mitigate risks, support due diligence,
                    and ensure regulatory compliance in various legal and
                    business scenarios.
                  </p>
                </div>
                <div className="cards-div">
                  <img alt="icon" src={Businesses} />
                  <p className="heading-2">Businesses</p>
                  <p className="body-text">
                    Businesses can confirm potential partners' credibility,
                    uncover undisclosed relationships, ensure compliance, and
                    safeguard business integrity, supporting informed and
                    transparent decision-making
                  </p>
                </div>
              </div>
            </section>

            <section className="who-are-we-section">
              <div className="outer-div">
                <div className="left-div">
                  <p className="section-heading dark-heading-color first-heading">
                    Who are We
                  </p>
                  <p className="heading-2 dark-text-color">
                    The end-to-end background intelligence platform
                  </p>
                  <p className="body-text dark-text-color">
                    Welcome to ProbeDigital, the pioneering force in Regulatory
                    Tech (RegTech). We empower you with real-time, accurate data
                    resources to effortlessly access information on companies,
                    directors, and share interests as well as registry checks
                    fror Judgements against courts in UK and UK properties owned
                    by overseas companies . As a leading tech company, we
                    redefine simplicity and efficiency. Our intuitive tool makes
                    your life easier, offering seamless navigation through
                    complex regulatory data. Say goodbye to tedious searches and
                    welcome a new era of streamlined information access.
                    Discover the future of regulatory intelligence with Probe
                    Digital. Embrace the power of simplicity, accuracy, and
                    speed – all at your fingertips. Join us and witness how
                    technology can transform the way you make informed decisions
                    effortlessly.
                  </p>
                </div>
              </div>
            </section>

            <section className="what-we-do-section" id="services">
              <p className="first-heading section-heading text-center dark-heading-color">
                What We Do
              </p>
              <p className="body-text text-center dark-text-color">
                Probe Digital streamlines the complexities of understanding
                information related to individuals and businesses in commercial
                dealings. We provide clients with the opportunity to gain
                valuable insights for effective due diligence and
                investigations. Our expert team, spanning Data Science,
                Anti-Money Laundering, Audit, Employee Recruitment, and Data
                Analysis, ensures a comprehensive and efficient approach.
              </p>

              <div className="cards-outer-div">
                <div className="cards-div">
                  <img alt="icon" src={LegalFirms} />
                  <p className="heading-2"> Judjement checker </p>
                  <p className="heading-2-light">
                    {' '}
                    Your Trusted Source for Official UK Register Verification.{' '}
                  </p>
                  <p className="body-text">
                    Your Key to Official Records Across the UK and Beyond via
                    Judgments & Fines. We offer quick access to official records
                    for various regions, including England, Wales, Scotland,
                    Northern Ireland, Isle of Man, Jersey, and the Republic of
                    Ireland. JudgementChecker is the unique service that lets
                    both the public and businesses instantly search the complete
                    record. Our services cover checking individuals and
                    businesses against official records across the UK and
                    beyond.
                  </p>
                </div>

                <div className="cards-div">
                  <img alt="icon" src={Individuals} />
                  <p className="heading-2"> Corporate Connections </p>
                  <p className="heading-2-light">
                    {' '}
                    Discover Insights & Connections{' '}
                  </p>
                  <p className="body-text">
                    Choose a person and explore their network, to gain insights
                    into their relationships with companies, directors and
                    shareholders. Extend the search by adding a secondary
                    entity, either another person or company and using Probes
                    Digitals propriety AI algorithm, to reveal complex
                    connections between individuals and companies or between
                    intermediaries including shareholders Unlock the power of
                    our cutting-edge solution for comprehensive investigations
                  </p>
                </div>
              </div>

              <div className="cards-outer-div">
                <div className="cards-div">
                  <img
                    style={{ height: '50px', width: '52px' }}
                    alt="icon"
                    src={GlobeProperty}
                  />
                  <p className="heading-2">
                    {' '}
                    The Foreign UK Ownership Checker{' '}
                  </p>
                  <p className="heading-2-light">
                    {' '}
                    Check which foreign entity owns assets in the UK{' '}
                  </p>
                  <p className="body-text">
                    The Foreign UK Ownership Checker Service empowers clients to
                    scrutinize our extensive data sets, providing insights into
                    the beneficial ownership of UK assets. Given that these
                    assets often involve overseas entities, discerning crucial
                    details such as the company name, jurisdiction, and type is
                    imperative for investigators. This information serves as a
                    foundational resource, enabling seamless cross-referencing
                    with other Probe services, notably the Corporate Link
                    Checker.
                  </p>
                </div>

                <div className="cards-div">
                  <img
                    style={{ width: '50px', height: '55px' }}
                    alt="icon"
                    src={SearchIcon}
                  />
                  <p className="heading-2"> Background Investigations </p>
                  <p className="heading-2-light">
                    {' '}
                    Full report including Judgement Check{' '}
                  </p>
                  <p className="body-text">
                    Select a person and our tool will scan all data sources and
                    establish any companies, assets owned, check judgments in
                    multiple UK jurisdictions, and check sanction lists,
                    financial and insolvency registers.
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>

              <div className="counter-outer-div primary-background-color light-text-color">
                <div className="first-big-heading">
                  <p className="section-heading">ProbeDigital by the Numbers</p>
                </div>
                <div>
                  <p className="main-title mb-0">50k+</p>
                  <p className="body-text">Reports Delivered</p>
                </div>
                <div>
                  <p className="main-title mb-0">1000+</p>
                  <p className="body-text">Satisfied Clients</p>
                </div>
                <div>
                  <p className="main-title mb-0">99.5%</p>
                  <p className="body-text">Data Accuracy</p>
                </div>
              </div>
            </section>

            <section className="our-qualities-section">
              <div className="our-services-outer-div">
                <div className="left-div">
                  <p className="section-heading first-heading">Our Services</p>
                  <ul>
                    <li className="body-text mb-2">Corporate Links</li>
                    <li className="body-text mb-2">
                      Network Analysis including Shareholder
                    </li>
                    <li className="body-text mb-2">Foreign UK Ownership</li>
                    <li className="body-text mb-2">Background Information</li>
                    <li className="body-text mb-2">Judgement Checker</li>
                  </ul>
                </div>
                <div className="our-services-outer-div second-divv">
                  <div className="right-div image-div">
                    <img alt="icon" src={OurApproach} />
                  </div>
                  <div className="left-div">
                    <p className="section-heading first-heading">
                      Our Approach
                    </p>

                    <p className="body-text mb-2">
                      Our approach is simple and transparent. We use open
                      sources, public records and with our propriety machine
                      learning algorithms we can uncover identify patterns and
                      connections.
                    </p>
                  </div>
                </div>
                <div className="our-services-outer-div">
                  <div className="left-div">
                    <p className="section-heading first-heading">Our Goal</p>
                    <p className="body-text mb-2">
                      Our goal is straightforward. We provide our clients with
                      information that will help them make informed decisions or
                      avoid costly ones.
                    </p>
                  </div>
                  <div className="right-div image-div">
                    <img alt="icon" src={OurGoal} />
                  </div>
                </div>
              </div>
            </section>

            <section className="signup-free-section" id="contactUs">
              <p className="main-title light-text-color text-center">
                Start enhancing your investigations with ProbeDigital Today!
              </p>
              {!user && (
                <button
                  className="button-text secondary-background-color dark-heading-color"
                  onClick={openSignup}
                >
                  Sign up Free Today
                </button>
              )}
            </section>

            <Footer />
            <CopywriteLiine />
          </div> */}
        </div>
      )}
    </>
  );
}

export default Home;
