import React from 'react';
import './Footer.css';
import FooterLogo from '../assets/images/pages/Home/footer-logo.svg';
import Facebook from '../assets/images/pages/Home/facebook-icon.png';
import Twitter from '../assets/images/pages/Home/twitter-icon.png';
import Youtube from '../assets/images/pages/Home/youtube-icon.png';
import Message from '../assets/images/pages/Home/message-footer.svg';
import Headset from '../assets/images/pages/Home/headset-footer.svg';
import Location from '../assets/images/pages/Home/footer-location.svg';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer-section">
      <div className="first-div">
        <img alt="icon here" src={FooterLogo} />
        <p className="small-text dark-text-color mb-4">
          Probe Digital is designed to ease the burden of trying to understand
          the links between people, companies and other entities.
        </p>
        <div className="footer-social-icons">
          <a href="#" target="_blank">
            <img alt="icon here" src={Facebook} />
          </a>
          <a href="#" target="_blank">
            <img alt="icon here" src={Twitter} />
          </a>
          <a href="#" target="_blank">
            <img alt="icon here" src={Youtube} />
          </a>
        </div>
      </div>
      <div className="second-div">
        <p className="heading-2 dark-heading-color">Services</p>
        <div className="mb-4">
          <a
            style={{ cursor: 'pointer' }}
            className="small-text dark-text-color text-decoration-none"
            target="_blank"
            onClick={() => {
              navigate('/selectEntity');
            }}
          >
            Search on Person
          </a>
        </div>
        <div className="mb-4">
          <a
            style={{ cursor: 'pointer' }}
            className="small-text dark-text-color text-decoration-none"
            target="_blank"
            onClick={() => {
              navigate('/selectEntity');
            }}
          >
            Search Person to Company Connection
          </a>
        </div>
        <div className="mb-4">
          <a
            style={{ cursor: 'pointer' }}
            className="small-text dark-text-color text-decoration-none"
            target="_blank"
            onClick={() => {
              navigate('/selectEntity');
            }}
          >
            Search Person to Person Connection
          </a>
        </div>
      </div>

      <div className="third-div">
        <p className="heading-2 dark-heading-color">Get In Touch</p>
        <div className="outer-div">
          <div>
            <img src={Headset} alt="Phone Icon" />
          </div>
          <div className="small-text dark-text-color">(+44) 207 046 0564</div>
        </div>
        <div className="outer-div">
          <div>
            <img src={Headset} alt="Phone Icon" />
          </div>
          <div className="small-text dark-text-color">(+44) 020 8144 2812</div>
        </div>
        <div className="outer-div">
          <div>
            <img src={Message} alt="Email Icon" />
          </div>
          <div className="small-text dark-text-color">
            contact@probedigital.co.uk
          </div>
        </div>
        <div className="outer-div">
          <div>
            <img src={Location} alt="Location Icon" />
          </div>
          <div className="small-text dark-text-color">London UK</div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
