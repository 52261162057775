import React from 'react';
import loadingGif from '../../../assets/images/loading.gif'; // Adjust the path to where your GIF is located

const TableLoadingScreen = ({ width = '20px' }) => (
  <div style={{ display: 'flex', width: '100%' }}>
    <div>
      <img style={{ width: width }} src={loadingGif} alt="Loading..." />
    </div>
  </div>
);

export default TableLoadingScreen;
