import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import './CESearchEntities.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBuilding, faUser, faPlus } from '@fortawesome/free-solid-svg-icons';
import PeopleAndContactItem from '../../../components/CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/PeopleAndContactItem';
import {
  calculateAge,
  calculateYearsUntilNow,
  debounce,
  isValidJSON,
  processRegisteredOfficeAddress,
  processRegisteredOfficeAddressObject,
} from '../../../utils/helper';
import {
  explorerFilter,
  explorerFilterOfficers,
  getNetworkGraphQueryByUserId,
  getRelationShips,
  saveNetworkGraphQueryByUserId,
} from '../../../api/corporateExplorer/corporateExplorer';
import CompanyItem from '../../../components/CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/CompanyItem';
import ExploreLinksOfficerCard from '../../../components/ExploreLinksOfficerCard/ExploreLinksOfficerCard';
import ProbeButton from '../../../components/ProbeButton/ProbeButton';
import loadingGif from '../../../assets/images/loading.gif';
import { useNavigate } from 'react-router-dom';
import CLSearchInput from '../../../components/CorporateLinks/CLSearchInput/CLSearchInput';
import { AppContext } from '../../../context/Context';

import CLGrapgLeftSidebarItemHolder from './../../../components/CorporateLinks/CLGraphLeftSidebar/CLGrapgLeftSidebarItemHolder/CLGrapgLeftSidebarItemHolder';
import GraphButton from '../../../components/GraphButton/GraphButton';
import RightSidebarMenu from '../../../components/GraphRightSideBar/GraphRightSideBar';
import { useLoading } from '../../../context/LoadingContext/LoadingContext';
import CESearchEntitiesFlowSection from './CESearchEntitiesFlowSection';
import LocalStorageService from '../../../utils/LocalStorageServices';
import URLService from '../../../utils/URLService';

const CESearchEntities = () => {
  const navigate = useNavigate();
  const {
    clGraph,
    setClGraph,
    user,
    corporateLinksAdvanceFilters,
    setCorporateLinksAdvanceFilters,
  } = useContext(AppContext);
  const { setIsLoading } = useLoading();
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const searchContainerRef = useRef(null);
  const [graphForTest, setGraphForTest] = useState(null);
  const [hideButtonsAndSearch, setHideButtonsAndSearch] = useState(false);
  const [isSectionVisible, setIsSectionVisible] = useState(true);
  const [queryHistory, setQueryHistory] = useState(null);
  const [isOpenSideBar, setIsOpenSidebar] = useState(false);
  const [totalOfficersAndCompanies, setTotalOfficersAndCompanies] =
    useState(null);

  // Function to handle selecting an item
  const setHistoricSuggestion = (historyItem, index) => {
    // Update the selected index on click
    setSelectedSuggestions(historyItem?.query || []);
    // Perform your logic with the clicked item
  };
  const getCombinationTypeAndIds = selectedSuggestions => {
    if (!selectedSuggestions || selectedSuggestions.length === 0) {
      return { combinationType: null, ids: [] };
    }

    const types = selectedSuggestions.map(suggestion => suggestion.type);
    const ids = selectedSuggestions.map(suggestion => {
      if (suggestion.type === 'Company') {
        return { type: 'company', id: suggestion.item['Company Number'] };
      } else if (suggestion.type === 'Officer') {
        return { type: 'officer', id: suggestion.item['officer_id'] };
      }
      return null;
    });

    // Remove null or empty ids
    const validIds = ids.filter(idObj => idObj && idObj.id);

    let combinationType = null;

    if (types.length === 1) {
      combinationType = types[0].toLowerCase();
    } else if (types.includes('Officer') && types.includes('Company')) {
      combinationType = 'officer-to-company';
    } else if (types.every(type => type === 'Officer')) {
      combinationType = 'officer-to-officer';
    } else if (types.every(type => type === 'Company')) {
      combinationType = 'company-to-company';
    }

    return { combinationType, ids: validIds };
  };

  const handleButtonClick = type => {
    setSuggestions([]);
    setInputValue([]);
    setSelectedType(type);
    setIsOpen(true);
    setIsLoadingPage(false);
    setTotalOfficersAndCompanies(null);
  };

  // this generate graphs and send ser to graph page
  const handleGraphPage = async () => {
    try {
      setIsLoading(true);
      let combination = getCombinationTypeAndIds(selectedSuggestions);
      if (user && user?.FirebaseUser && user?.FirebaseUser?.user_id) {
        combination['userId'] = user?.FirebaseUser?.user_id;
      }
      let nodeGraph = await getRelationShips(combination);
      if (!nodeGraph?.status) {
        setIsLoading(false);
        return;
      }

      setClGraph(previousState => ({
        ...previousState,
        selectedSuggestions: selectedSuggestions,
        graph: nodeGraph?.result?.graphs,
        combination: combination,
        isOpenRightSidebar: false,
        selectedGraph: null,
        shareholders: null,
      }));

      if (!user?.hasOwnProperty('guest')) {
        await saveNetworkGraphQueryByUserId({
          userId: user?.FirebaseUser?.user_id,
          query: selectedSuggestions,
        });
      }

      setIsLoading(false);
      navigate(
        `/cl-dashboard/cl-graph?selectedSuggestions=${JSON.stringify(selectedSuggestions)}`
      );
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleInputFocus = () => {
    setIsOpen(true);
  };

  const handleInputBlur = () => {
    // Allow the dropdown to remain open when focusing on buttons
    setTimeout(() => {
      if (
        !document.activeElement.classList.contains(
          'cesearch-entites-dropdown-button'
        )
      ) {
        // setIsOpen(false);
      }
    }, 300);
  };

  const debouncedSearchCompanyByName = useCallback(
    debounce(async (companyHouseSearchName, filters = null) => {
      if (!companyHouseSearchName) return setSuggestions([]);
      setIsLoadingPage(true);

      let searchQuery = [
        { header: 'company_name', filter: ['+' + companyHouseSearchName] },
      ];

      if (filters) {
        if (filters?.locality !== '') {
          searchQuery.push({
            header: 'registered_address',
            filter: [`${filters?.locality}__town`],
          });
        }

        if (filters?.companyType !== '') {
          searchQuery.push({ header: 'type', filter: [filters?.companyType] });
        }

        const { active, dissolved } = filters?.companyStatus || {};

        if (active !== undefined && dissolved !== undefined) {
          const filter = [];

          if (active) {
            filter.push('active');
          }

          if (dissolved) {
            filter.push('dissolved');
          }

          if (filter.length > 0) {
            searchQuery.push({
              header: 'company_status',
              filter: filter,
            });
          }
        }

        // handle date range
        let dateRange = filters?.dateRange;
        let dateRangeMin = dateRange[0];
        let dateRangeMax = dateRange[1];
        searchQuery.push({
          header: 'date_of_creation',
          filter: [`${dateRangeMin}-01-01 - ${dateRangeMax}-01-01`],
        });
      }

      try {
        const entitiesWithOptions = await explorerFilter(searchQuery);

        let accountsinfo = entitiesWithOptions?.accountsInfo;

        let mapResults = entitiesWithOptions?.result
          ?.map(company => ({
            id: company?._id,
            title: company?._source?.company_name,
            address: processRegisteredOfficeAddress(company?._source),
            addressObj: processRegisteredOfficeAddressObject(company?._source),
            date_of_creation: company?._source?.date_of_creation,
            company_status: company?._source?.company_status,
            company_number: company?._source?.company_number,
            confirmation_statement: company?._source?.confirmation_statement,
            accounts: company?._source?.accounts,
            sic_codes: company?._source?.sic_codes,
            pofficers_list: company?._source?.pofficers_list,
          }))
          .slice(0, 5);
        setSuggestions(mapResults || []);
        setTotalOfficersAndCompanies(entitiesWithOptions?.pagination?.total);
        setIsLoadingPage(false);
      } catch (error) {
        console.error('Failed to fetch company suggestions:', error);
      } finally {
        setIsLoadingPage(false);
      }
    }, 600),
    [] // Only create this function once
  );

  const debouncedSearchOfficerByName = useCallback(
    debounce(async (officerName, filters = null) => {
      if (!officerName) return setSuggestions([]);
      setIsLoadingPage(true);
      let queryBody = {
        name: officerName,
      };
      if (filters) {
        queryBody['filters'] = {
          nationality: filters?.nationality,
          occupation: filters?.occupation,
          role: filters?.positionRole,
          age: {
            min: filters?.dateRange[0]?.toString(),
            max: filters?.dateRange[1]?.toString(),
          },
        };
      }
      try {
        const offficersRes = await explorerFilterOfficers(queryBody);
        setSuggestions(offficersRes?.officers || []);
        setTotalOfficersAndCompanies(offficersRes?.total);
        setIsLoadingPage(false);
      } catch (error) {
        console.error('Failed to fetch company suggestions:', error);
      } finally {
        setIsLoadingPage(false);
      }
    }, 600),
    [] // Only create this function once
  );

  const handleChange = event => {
    let { value } = event.target;
    setInputValue(value);
    value = value.trim().toLowerCase(); // Convert the value to lowercase and trim spaces

    // Perform the search only if there's input
    if (value === '') {
      setSuggestions([]);
      setTotalOfficersAndCompanies(null);
      return;
    }

    if (selectedType === 'People') {
      debouncedSearchOfficerByName(value);
    } else if (selectedType === 'Companies') {
      debouncedSearchCompanyByName(value);
    }
  };

  // const handleCardClick = () => {
  //   setIsSectionVisible(false);
  // };
  const itemClicked = (type, selectedItem) => {
    setIsSectionVisible(false);
    if (selectedSuggestions?.length >= 2) {
      return;
    }
    if (type === 'Companies') {
      let companyObj = mapCompanyObject(selectedItem);
      updateSuggestions(companyObj);
    } else {
      let officerObj = mapOfficerObject(selectedItem);
      updateSuggestions(officerObj);
    }
    setHideButtonsAndSearch(true);
  };

  const mapCompanyObject = original => {
    return {
      type: 'Company',
      subType: `Companies House ${original?.title} informaton`,
      title: original?.title,
      address: `${original?.addressObj.addressOne}, ${original?.addressObj?.locality}, ${original?.addressObj?.country}`,
      item: {
        Status:
          original?.company_status.charAt(0).toUpperCase() +
          original?.company_status.slice(1),
        Type: 'LTD',
        Incorporated: {
          main: original?.date_of_creation,
          sub: calculateYearsUntilNow(original?.date_of_creation),
        },
        'Company Number': original?.company_number,
      },
    };
  };

  const mapOfficerObject = original => {
    const formatDOB = dob => {
      try {
        const { year, month, day } = dob;
        return `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`;
      } catch (e) {
        return '';
      }
    };

    return {
      type: 'Officer',
      subType: `Companies house ${original.name} information`,
      title: original.name,
      address: `${original.address.address_line_1}, ${original.address.postal_code}, ${original.address.country}`,
      item: {
        Status: original.resigned_on ? 'Resigned' : 'Active',
        Occupation: original.occupation,
        DOB: {
          main: formatDOB(original.date_of_birth),
          sub: calculateAge(original?.date_of_birth),
        }, // Adjust "sub" as needed
        Nationality:
          original.nationality.charAt(0).toUpperCase() +
          original.nationality.slice(1).toLowerCase(),
        Role: original.officer_role,
        officer_id: original.person_number,
      },
    };
  };

  const updateSuggestions = newObject => {
    setSelectedSuggestions(prevSuggestions => {
      // Check if an object of the same type already exists
      const exists = prevSuggestions.some(
        suggestion => suggestion.title === newObject.title
      );

      if (exists) {
        // Replace the existing object with the new object
        return prevSuggestions.map(suggestion =>
          suggestion.title === newObject.title ? newObject : suggestion
        );
      } else {
        // Add the new object
        return [...prevSuggestions, newObject];
      }
    });
  };

  const deleteByName = title => {
    setSelectedSuggestions(prevSuggestions =>
      prevSuggestions.filter(suggestion => suggestion.title !== title)
    );
  };

  const deleteByType = type => {
    setSelectedSuggestions(prevSuggestions =>
      prevSuggestions.filter(suggestion => suggestion.type !== type)
    );
  };

  const removeItem = title => {
    deleteByName(title);
    URLService.removeParam('selectedSuggestions');
  };

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setIsOpenSidebar(!isOpenSideBar);
  };

  const handleItemClick = (historyItem, index) => {
    setHistoricSuggestion(historyItem, index);
  };

  useEffect(() => {
    if (user && !user?.hasOwnProperty('guest')) {
      const getAllQuriesByUserId = async () => {
        try {
          let networksQuries = await getNetworkGraphQueryByUserId(
            user?.FirebaseUser?.user_id
          );
          setQueryHistory(networksQuries);
        } catch (error) {}
      };
      getAllQuriesByUserId();
    }
  }, [user]);

  useEffect(() => {
    let paramSS = URLService.getParam('selectedSuggestions');
    if (paramSS && isValidJSON(paramSS)) {
      setSelectedSuggestions(JSON.parse(paramSS));
    }
  }, []);

  useEffect(() => {
    if (inputValue.length > 0) {
      if (selectedType === 'People') {
        debouncedSearchOfficerByName(
          inputValue,
          corporateLinksAdvanceFilters?.officerFilters
        );
      } else if (selectedType === 'Companies') {
        debouncedSearchCompanyByName(
          inputValue,
          corporateLinksAdvanceFilters?.companyFilters
        );
      }
    }
  }, [corporateLinksAdvanceFilters, selectedType]);

  return (
    <div>
      <div className="dashboard-section">
        <div className="main-div">
          <div className="cesearch-entites-dashboard-content">
            {!user?.hasOwnProperty('guest') && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  paddingTop: '30px',
                }}
              >
                <div
                  className="close-icon-recent-search"
                  onClick={toggleSidebar}
                  style={{
                    transform: isOpenSideBar
                      ? 'translateX(0)'
                      : 'translateX(-270px)',
                  }}
                >
                  ×
                </div>

                {/* Sidebar */}
                <div
                  className="sidebar-recent-search"
                  style={{
                    transform: isOpenSideBar
                      ? 'translateX(0)'
                      : 'translateX(-250px)',
                  }}
                >
                  {queryHistory &&
                    Array.isArray(queryHistory) &&
                    queryHistory?.map((historyItem, index) => (
                      <div
                        key={index}
                        className="history-item"
                        onClick={() => handleItemClick(historyItem, index)}
                        style={{
                          cursor: 'pointer',
                          padding: '10px',
                          margin: '5px 0',
                          borderRadius: '4px',
                          transition: 'background-color 0.3s ease',
                          backgroundColor: '#f0f0f0',
                        }}
                      >
                        {Array.isArray(historyItem?.query) ? (
                          historyItem.query.map((item, idx) => (
                            <div
                              key={idx}
                              style={{ color: '#333', fontSize: '14px' }}
                            >
                              {item.title}
                              {historyItem.query.length > 1 &&
                              idx < historyItem.query.length - 1 ? (
                                <span>
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    style={{
                                      color: 'black',
                                      width: '25px',
                                      borderRadius: '5px',
                                    }}
                                  />
                                </span>
                              ) : (
                                ''
                              )}
                              {item.company}
                            </div>
                          ))
                        ) : (
                          <div style={{ color: '#333', fontSize: '14px' }}>
                            No query found
                          </div>
                        )}
                      </div>
                    ))}
                </div>
                {/* Toggle Button Icon outside Sidebar */}
                {!isOpenSideBar && (
                  <div className="toggle-button" onClick={toggleSidebar}>
                    ☰
                  </div>
                )}
              </div>
            )}
            <CESearchEntitiesFlowSection
              searchContainerRef={searchContainerRef}
              inputValue={inputValue}
              handleInputFocus={handleInputFocus}
              handleInputBlur={handleInputBlur}
              handleChange={handleChange}
              selectedSuggestions={selectedSuggestions}
              handleGraphPage={handleGraphPage}
              isOpen={isOpen}
              selectedType={selectedType}
              handleButtonClick={handleButtonClick}
              isLoadingPage={isLoadingPage}
              loadingGif={loadingGif}
              suggestions={suggestions}
              removeItem={removeItem}
              itemClicked={itemClicked}
              setSelectedType={setSelectedType}
              hideButtonsAndSearch={hideButtonsAndSearch}
              setHideButtonsAndSearch={setHideButtonsAndSearch}
              setSuggestions={setSuggestions}
              setIsOpen={setIsOpen}
              totalOfficersAndCompanies={totalOfficersAndCompanies}
              // isSectionVisible={isSectionVisible}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CESearchEntities;
