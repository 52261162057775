import React, { useEffect } from 'react';
import '../CorporateExploreInsight.css';
import CEIPersonInsightsAppointments from '../../../components/CorporateExploreInsights/CEIPersonInsights/CEIPersonInsightsAppointments/CEIPersonInsightsAppointments';
import CEIPersonInsightsDetails from '../../../components/CorporateExploreInsights/CEIPersonInsights/CEIPersonInsightsDetails/CEIPersonInsightsDetails';
import CEIPersonInsightsMutualPeople from '../../../components/CorporateExploreInsights/CEIPersonInsights/CEIPersonInsightsMutualPeople/CEIPersonInsightsMutualPeople';
import CEIPersonInsightsCompanies from '../../../components/CorporateExploreInsights/CEIPersonInsights/CEIPersonInsightsCompanies/CEIPersonInsightsCompanies';
import CEIPersonInsightsBio from '../../../components/CorporateExploreInsights/CEIPersonInsights/CEIPersonInsightsBio/CEIPersonInsightsBio';

function CEIPersonInsightsLatestOverview({
  selectedOfficer,
  forceMobileView = false,
}) {
  const data = {
    intro: selectedOfficer?.intro,
    appointments: selectedOfficer?.appointments,
    details: selectedOfficer?.details,
    mutual_people: selectedOfficer?.mutual_people,
    companies: selectedOfficer?.companies,
    address: selectedOfficer?.details?.address,
  };

  return (
    <>
      <div className="col-lg-12">
        <div
          className={`corporate-explore-insight-gridContainer ${forceMobileView ? 'corporate-explore-insight-gridContainer-forece-mobileview' : ''}`}
        >
          <div className="corporate-explore-insight-gridItem">
            <CEIPersonInsightsBio details={data} />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CEIPersonInsightsAppointments appointments={data.appointments} />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CEIPersonInsightsDetails details={data.details} />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CEIPersonInsightsMutualPeople mutual_people={data.mutual_people} />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CEIPersonInsightsCompanies companies={data.companies} />
          </div>
        </div>
      </div>
    </>
  );
}

export default CEIPersonInsightsLatestOverview;
