import React from 'react';
import './SearchOnPerson.css';
import SearchName from '../../../../components/SearchName';
import DashboardImage from '../../../../assets/images/pages/Dashboard/dashboard-background-graphics.svg';
import { Link } from 'react-router-dom';
import HeaderDropdown from '../../../../components/HeaderDropdownNative';
function SearchOnPerson() {
  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className="dashboard-content-div">
          <SearchName />
          <div className="dashboard-graphics">
            <img src={DashboardImage} alt="icon here" />
            <p>Discover People & Corporate Connections</p>
          </div>
        </div>
      </div>
      <Link to="/search-result">
        <button className="bottom-btn-div">Search</button>
      </Link>
    </div>
  );
}

export default SearchOnPerson;
