import './CESearchEntities.css';
import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons';
import CompanyItem from '../../../components/CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/CompanyItem';
import InsightCard from '../../../components/CorporateExploreInsights/CEIInsightResultsCard/CEIinsightResultsCard';
import CESAdvancedFilter from './CESAdvancedFilter/CESAdvancedFilter';
import CESAdvancedFilterPerson from './CESAdvancedFilterPerson/CESAdvancedFilterPerson';
import {  useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/Context';

const CESearchEntitiesSection = ({
  searchContainerRef,
  inputValue,
  handleInputFocus,
  handleInputBlur,
  handleChange,
  selectedSuggestions,
  handleGraphPage,
  isOpen,
  selectedType,
  handleButtonClick,
  isLoadingPage,
  loadingGif,
  suggestions,
  itemClicked,
  showCards = true,
  totalOfficersAndCompanies,
}) => {
    const { handleNavigation, setHandleNavigation } = useContext(AppContext);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(true);
  const navigate = useNavigate();
  const toggleAdvancedFilter = e => {
    e.preventDefault(); 
    setShowAdvancedFilter(prev => !prev); 
  };

  const totalOfficerAndComapnies = (company) => {

   
       setHandleNavigation(previousState => ({
         ...previousState,
         searchInsight: inputValue,
       }));
        navigate('/cei-dashboard/cei-corporate-explore-insight');
  }
  return (
    <>
      <section className="cesearch-entites-explorer-link-section">
        <div
          className="cesearch-entites-search-container"
          ref={searchContainerRef}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <input
              type="text"
              className="cesearch-entites-search-input"
              value={inputValue}
              placeholder={selectedType}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onChange={handleChange}
            />
          </div>

          {isOpen && (
            <div className="cesearch-entites-dropdown-main">
              {inputValue.length > 0 && (
                <span className="cesearch-entites-heading">
                  <span className="cesearch-filter">
                    <span
                      style={{ color: 'blue', cursor: 'pointer' }}
                      onClick={toggleAdvancedFilter}
                    >
                      Advanced Filter
                    </span>
                  </span>
                  {showAdvancedFilter && (
                    <div className="cesearch-entites-advanced-filter">
                      {selectedType === 'People' ? (
                        <CESAdvancedFilterPerson />
                      ) : (
                        <CESAdvancedFilter />
                      )}
                    </div>
                  )}
                </span>
              )}

              {showCards && (
                <div className="cesearch-entites-buttons-container">
                  <button
                    className={`cesearch-entites-dropdown-button  ${
                      selectedType === 'People' ? 'active' : ''
                    }`}
                    onClick={() => handleButtonClick('People')}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ marginRight: '5px' }}
                    />
                    People
                  </button>
                  <button
                    className={`cesearch-entites-dropdown-button  ${
                      selectedType === 'Companies' ? 'active' : ''
                    }`}
                    onClick={() => handleButtonClick('Companies')}
                  >
                    <FontAwesomeIcon
                      icon={faBuilding}
                      style={{ marginRight: '5px' }}
                    />
                    Companies
                  </button>
                </div>
              )}

              <div className="cesearch-entites-main-cards">
                <div className="cesearch-entites-main-cards-header">
                  <span className="cesearch-entites-main-cards-heading">
                    {selectedType}
                  </span>
                  {totalOfficersAndCompanies && (
                    <div
                      className="people-officers-count"
                      onClick={() => totalOfficerAndComapnies('Companies')}
                    >
                      <div>{totalOfficersAndCompanies}</div>
                    </div>
                  )}
                  {isLoadingPage && (
                    <div>
                      <img src={loadingGif} alt="Loading..." width={20} />
                    </div>
                  )}
                </div>

                {!isLoadingPage && (
                  <div className="cesearch-entites-main-All-cards">
                    {selectedType === 'People'
                      ? suggestions &&
                        suggestions.map(person => (
                          <div
                            key={person.id} // Ensure unique keys for each mapped element
                            onClick={() => itemClicked(selectedType, person)}
                          >
                            <InsightCard officer={person} />
                          </div>
                        ))
                      : suggestions &&
                        suggestions.map(comp => (
                          <div
                            key={comp.id} // Ensure unique keys for each mapped element
                            onClick={() => itemClicked(selectedType, comp)}
                          >
                            <CompanyItem company={comp} isCardView={true} />
                          </div>
                        ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default CESearchEntitiesSection;
