import React from 'react';
import './StartJourney.css';

const StartJourney = () => {
  return (
    <div className="start-journey-container">
      <div className="start-journey-content">
        <h4>Lets get in touch!</h4>
        <h2>Ready to start your Journey</h2>
        <p>
          Find top-rated services at unbeatable prices, handpicked to match your
          needs. From individuals to everyday companies, we’ve got you covered.
        </p>
      </div>
      <button className="start-journey-button">SIGN UP NOW</button>
    </div>
  );
};

export default StartJourney;
